import React from 'react';

import Hero from '../../Components/Hero/Hero';
import SmallText from '../../Components/SmallText/SmallText';
import Carousel from '../../Components/Carousel/Carousel';
import IconsText from '../../Components/IconsText/IconsText';
import ImageText from '../../Components/ImageText/ImageText';
import VerticalList from '../../Components/VerticalList/VerticalList';
import BlocksCarousel from '../../Components/BlocksCarousel/BlocksCarousel';
import Contact from '../../Components/Contact/Contact';

const sales = props => {
  const { content, deviceWidth } = props;

  return (
    <>
      <Hero deviceWidth={deviceWidth} content={content.salesHero} />
      <IconsText deviceWidth={deviceWidth} content={content.whatAwaits} />
      <ImageText
        deviceWidth={deviceWidth}
        content={content.salesJob}
        customClass="imageText--split-half"
      />
      <VerticalList content={content.audibeneModel} customClass="section--grey" />
      <SmallText content={content.homeOffice} />
      <Carousel deviceWidth={deviceWidth} content={content.carouselSales} />
      <BlocksCarousel deviceWidth={deviceWidth} content={content.audibeneKultur} />
      <VerticalList content={content.apply} customClass="section--blue" />
      <Contact content={content.salesContact} />
    </>
  );
};

export default sales;
