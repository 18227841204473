import React from 'react';
import './Awards.css';

const awards = props => {
  const assets = process.env.PUBLIC_URL + '/Assets/';
  const {
    content: { items }
  } = props;

  return (
    <section className="awards">
      <div className="awards__wrapper">
        {items.map((el, i) => {
          return (
            <div key={i} className="awards__item">
              <img alt="" className="awards_icon" src={assets + el.icon} />
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default awards;
