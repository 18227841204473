import React from 'react';
import './Trust.css';

const trust = props => {
  const assets = process.env.PUBLIC_URL + '/Assets/';
  const {
    content: { img },
    deviceWidth
  } = props;

  return (
    <section id="trust" className="trust">
      <div className="trust__wrapper">
        <img
          alt=""
          className="trust__screenshot"
          src={assets + (deviceWidth < 992 ? img.mobile : img.desktop)}
        />
      </div>
    </section>
  );
};

export default trust;
