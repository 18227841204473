const data = {
  head: {
    title: 'Aktuelle Stellenangebote bei audibene',
    description:
      'Unsere Vision ist es, Menschen weltweit zu besserem Hören zu verhelfen. Werde Teil unseres bunten Teams und mache die Welt ein bisschen glücklicher.',
    favicon: 'favicon-de.ico'
  },
  benefits: {
    title: 'Benefits',
    items: [
      {
        title: 'Free fruits & drinks',
        icon: 'de/Benefits/fruits.svg',
        text:
          'We provide for you free drinks, cereals and snacks, daily. We also regulary support your health with fresh fruits and tasty vegetables. Yummy!'
      },
      {
        title: 'Fitness',
        icon: 'de/Benefits/fitness.svg',
        text:
          'With our audibene Gympass membership, you get discounted access to more than 2,000 different fitness, sports and wellness providers in Germany.'
      },
      {
        title: 'Come as you are',
        icon: 'de/Benefits/shoe.svg',
        text:
          'At audibene, everyone comes to the office as they are. Casual in shorts and sneakers or fancy in shirt and chinos. Be yourself, feel comfortable.'
      },
      {
        title: 'Startup feeling',
        icon: 'de/Benefits/rocket.svg',
        text:
          'Even if we are one not anymore. A kicker table, a grill, a rooftop terrace and regular team and company events are still key pieces of our daily company life.'
      }
    ]
  },
  trustwidgets: {
    items: [
      {
        url:
          'https://www.kununu.com/de/audibene1?utm_source=widget&utm_campaign=widget_selfservice_scoreandrevie',
        imgSrc: 'https://www.kununu.com/de/partner/KlpeDFU%3D/self-service-button?button-type=2',
        imgTitle: 'kununu'
      },
      {
        url: 'https://www.glassdoor.de/Überblick/Arbeit-bei-audibene-EI_IE1155737.11,19.htm',
        imgSrc: 'https://www.glassdoor.de/api/widget/verticalStarRating.htm?e=1155737',
        imgTitle: 'glassdoor'
      }
    ]
  },
  awards: {
    items: [
      {
        icon: 'de/Awards/kununu-open-company_de.png'
      },
      {
        icon: 'de/Awards/kununu-top-company_de.png'
      },
      {
        icon: 'de/Awards/glassdoor_en.jpg'
      }
    ]
  },
  carousel: {
    slides: [
      {
        img: {
          mobile: 'de/Carousel/miracle_m.jpg',
          desktop: 'de/Carousel/miracle.jpg'
        },
        title: 'From a Berlin start-up to one of the leading hearing care companies worldwide',
        text:
          'We are the fastest growing hearing care company ever. Shortly after starting in Berlin we expanded from there to two further continents. Today, we have offices in Berlin, Mainz, Miami, Denver, Toronto, Paris, Kuala Lumpur, Delhi, Utrecht, Berne and Seoul.'
      },
      {
        img: {
          mobile: 'de/Carousel/marco_and_paul_m.jpg',
          desktop: 'de/Carousel/marco_and_paul.jpg'
        },
        title: 'A vision is born',
        text:
          'Our founders Marco Vietor and Paul Crusius already knew each other from university. Together, they had the idea to fight prejudices about hearing aids. Ultimately, they wanted to help people, in an innovative and simple way, to better hearing - and thus, a better quality of life.'
      },
      {
        img: {
          mobile: 'de/Carousel/wohnzimmer_m.jpg',
          desktop: 'de/Carousel/wohnzimmer.jpg'
        },
        title: 'It all started in this living room',
        text:
          "In 2012 Marco, Paul and 3 other colleagues started audibene. Actually, our first phone calls were done out of Marco's living room. Without any desks, only with laptops and phones."
      },
      {
        img: {
          mobile: 'de/Carousel/koepenicker_m.jpg',
          desktop: 'de/Carousel/koepenicker.jpg'
        },
        title: 'Our first office',
        text:
          'Three months later they moved into the first real office in Berlin Kreuzberg: desks for everyone and plenty of space. From then on, they helped more and more people every day and our team grew so quickly that it soon became quite crowded again...'
      },
      {
        img: {
          mobile: 'de/Carousel/zaudibene_m.jpg',
          desktop: 'de/Carousel/zaudibene.jpg'
        },
        title: 'Growing up',
        text:
          'Two more relocations and a number of new colleagues later, we opend up the audibene headquarters with around 250 employees in Prenzlauer Berg Berlin and another office in Mainz. It was important to maintain a living room dynamic.'
      },
      {
        img: {
          mobile: 'de/Carousel/us-office_m.jpg',
          desktop: 'de/Carousel/us-office.jpg'
        },
        title: 'Higher, faster, further',
        text:
          'Our vision was well received. Not only in Germany, but in other parts of Europe, in the US and in Asia, people wanted to improve their lives with audibene. Today, all colleagues from over 45 nations ensure good and easy hearing in over 11 countries all around the world.'
      }
    ]
  },
  development: {
    title: 'Develop with us',
    text:
      "With us, you have lots of opportunities to develop what you would like. We believe that people are only happy in their jobs if they are given enough freedom to reach their potential. So that we don't stand still – and you don't either – we always rely on customized training and a change of perspective.",
    items: [
      {
        title: 'In the team',
        img: 'de/Development/portrait_01.jpg',
        innerTitle: 'Christina',
        innerSubtitle: 'Head of Customer Sales Mainz',
        innerCopy:
          "„I joined audibene Berlin in 2015 as a customer consultant. In the meantime, I've moved back to my beloved hometown, Mainz, and become the lead of one of our customer consultant teams.“"
      },
      {
        title: 'In the company',
        img: 'de/Development/portrait_02.jpg',
        innerTitle: 'Moritz',
        innerSubtitle: 'Business Analyst',
        innerCopy:
          '„As an intern, I was able to see behind the curtains of many different areas and teams. I was always interested in data. Today I am a business analyst at audibene – anyone who has a question about data comes to me.“'
      },
      {
        title: 'Personally',
        img: 'de/Development/portrait_03.jpg',
        innerTitle: 'Frania',
        innerSubtitle: 'Sales Operations Manager',
        innerCopy:
          '„Traveling is my passion – privately and professionally. As a coach for our hearing aid experts, I have worked in Toronto and Miami for several months. Further trips for audibene are on the way!“'
      }
    ]
  },
  footer: {
    social: [
      {
        title: 'LinkedIn',
        icon: 'linkedin.svg',
        url:
          'https://www.linkedin.com/company/audibene?utm_source=Career%20Website&utm_medium=Icon&utm_campaign=Employer%20Branding'
      },
      {
        title: 'Xing',
        icon: 'xing.svg',
        url:
          'https://www.xing.com/pages/audibene?utm_source=Career%20Website&utm_medium=Icon&utm_campaign=Employer%20Branding'
      },
      {
        title: 'Instagram',
        icon: 'instagram.svg',
        url:
          'https://www.instagram.com/weareaudibene/?utm_source=Career%20Website&utm_medium=Icon&utm_campaign=Employer%20Branding'
      },
      {
        title: 'youtube',
        icon: 'youtube.svg',
        url: 'https://www.youtube.com/channel/UCgxXCeJf-rlwNQK73J2nyPw'
      },
      {
        title: 'Blog',
        icon: 'blog.svg',
        url:
          'https://inside.audibene.de/?utm_source=Career%20Website&utm_medium=Icon&utm_campaign=Employer%20Branding'
      }
    ],
    international: {
      title: 'audibene/hear.com international:',
      links: [
        {
          title: 'Netherlands',
          icon: 'flag_netherlands@2x.png',
          url: 'https://www.audibene.nl'
        },
        {
          title: 'Switzerland',
          icon: 'flag_switzerland@2x.png',
          url: 'https://www.audibene.ch'
        },
        {
          title: 'Canada',
          icon: 'flag_canada@2x.png',
          url: 'https://www.hear.com/ca'
        },
        {
          title: 'France',
          icon: 'flag_france@2x.png',
          url: 'https://www.audibene.fr/'
        },
        {
          title: 'USA',
          icon: 'flag_usa@2x.png',
          url: 'https://www.hear.com/'
        },
        {
          title: 'India',
          icon: 'IN_flag@2x.png',
          url: 'https://www.hear.com/in'
        },
        {
          title: 'Malaysia',
          icon: 'flag_malaysia@2x.png',
          url: 'https://www.hear.com/my'
        },
        {
          title: 'Germany',
          icon: 'flag_germany@2x.png',
          url: 'https://www.audibene.de/'
        },
        {
          title: 'South Korea',
          icon: 'flag_south_korea@2x.png',
          url: 'https://www.hear.com/kr'
        }
      ]
    },
    nav: [
      {
        title: 'Imprint',
        url: 'https://www.audibene.de/impressum/'
      },
      {
        title: 'Terms & Conditions',
        url: 'https://www.audibene.de/agb-datenschutz/'
      },
      {
        title: 'Privacy Policy',
        url: 'https://www.audibene.de/datenschutz/'
      },
      {
        title: 'Press',
        url: 'https://www.audibene.de/presse/'
      }
    ]
  },
  header: {
    url: 'https://www.audibene.de/',
    logo: 'https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/_BRAND/DE/audibene-claim.svg',
    items: [
      {
        text: 'This is us',
        anchor: 'who'
      },
      {
        text: 'Benefits',
        anchor: 'benefits'
      },
      {
        text: 'Jobs',
        anchor: 'jobs'
      }
    ]
  },
  hero: {
    img: {
      mobile: 'de/Hero/hero-audibene-de.jpg',
      desktop: 'de/Hero/hero-audibene-de.jpg'
    },
    title: 'Jobs at audibene',
    subtitle: "We make people's lives better.",
    cta: {
      text: 'Open positions',
      anchor: 'jobs'
    },
    videoId: '389282513'
  },
  jobs: {
    title: 'Jobs'
  },
  offices: {
    title: {
      mobile: 'Locations in Germany',
      desktop: 'Locations in Germany - Where do you want to work?'
    },
    items: [
      [
        {
          block: 'image',
          img: 'de/Offices/o_01.jpg'
        },
        {
          block: 'text',
          title: 'Berlin',
          text:
            'In our headquarter in Berlin, you can find about 250 colleagues, 5 dogs and 200m² roof-top terrace. On 4 floors with loft character, we provide great hearing all around the world. This is the place where most of our global teams as well as our European sales teams are located.'
        },
        {
          block: 'image',
          img: 'de/Offices/o_02.jpg'
        }
      ],
      [
        {
          block: 'image',
          img: 'de/Offices/o_03.jpg'
        },
        {
          block: 'image',
          img: 'de/Offices/o_04.jpg'
        },
        {
          block: 'text',
          title: 'Mainz',
          text:
            'At the RheinCampus in Mainz, where we  combine our training center for audiologists, a hearing studio with state-of-the-art technology, with four sales teams - all with a beautiful view of the Rhine river.'
        }
      ],
      [
        {
          block: 'text',
          title: 'Home Office',
          titleMobile: 'Home Office',
          text:
            'Your home is a more beautiful office? At audibene, you always have the opportunity to work a couple days (or always) from home. Thanks to state-of-the-art video communication technology, you can work comfortably from home, but still feel closely connected to your colleagues.'
        },
        {
          block: 'image',
          img: 'de/Offices/o_05.jpg'
        },
        {
          block: 'image',
          img: 'de/Offices/o_06.jpg'
        }
      ]
    ],
    single: {
      title: 'Wähle dein Traum-Büro',
      text:
        'Wir bieten dir die Möglichkeit, deutschlandweit mit uns zu arbeiten. Du entscheidest dich zwischen: Großstadtluft in unserem loftartigen Headquarter in Berlin, Blick auf den Rhein in unserem Office im idyllischen Mainz oder deinen ganz eigenen vier Wänden bei dir zu Hause.'
    }
  },
  values: {
    title: 'Our values',
    items: [
      {
        title: 'Change lives',
        icon: 'de/Values/change-lives.svg',
        text: "We change people's lives with better hearing. This is what drives us."
      },
      {
        title: 'One Team',
        icon: 'de/Values/one-team.svg',
        text: 'We truly enjoy working together. We have fun and love celebrating.'
      },
      {
        title: 'Entrepreneurs',
        icon: 'de/Values/entrepreneurs.svg',
        text: 'We are entrepreneurs. We take risks and make things happen fast.'
      },
      {
        title: 'Growth',
        icon: 'de/Values/growth.svg',
        text: 'We focus on growth. Personal growth. Team growth. Customer growth.'
      },
      {
        title: 'Responsibility',
        icon: 'de/Values/responsibility.svg',
        text: 'You are in charge. We have no organizational chart.'
      }
    ]
  },
  who: {
    title: 'This is us',
    text:
      'Our vision is to help people around the world to hear better. Thanks to state-of-the-art technology with an almost invisible design, today, nobody has to live with bad hearing. Become part of our colourful team and make the world a better place.',
    success: {
      title: 'Our success story',
      items: [
        {
          number: '2012',
          text: 'Founded'
        },
        {
          number: '8',
          text: 'Countries'
        },
        {
          number: '1.200',
          text: 'Colleagues'
        },
        {
          number: '45',
          text: 'Nationalities'
        }
      ]
    }
  },
  work: {
    title: 'How we work',
    blocks: [
      {
        img: {
          desktop: 'de/Work/presentation.jpg',
          mobile: 'de/Work/presentation-mobile.jpg'
        },
        textBlock: {
          title: 'One team, one dream',
          text:
            "We work on projects across teams and don't think or act in departments. Hierarchies hardly play a role. Instead, we communicate on equal terms and value each other's opinions - no matter what your experience level is."
        }
      }
    ]
  },
  video: {
    videoId: '447172538',
    playImg: 'de/Video/play.svg',
    coverImg: 'de/Video/video-cover.jpg'
  },
  // sales page
  salesHero: {
    img: {
      mobile: 'de/Hero/hero-audibene-de-sales.jpg',
      desktop: 'de/Hero/hero-audibene-de-sales.jpg'
    },
    title: 'Finde deinen Traumjob',
    subtitle: 'In unserem Sales Team.',
    cta: {
      text: 'Jetzt bewerben!',
      link: 'https://boards.greenhouse.io/audibenehearcom/jobs/2205293'
    }
  },
  // sales campaign page
  salesCampaignHero: {
    img: {
      mobile: 'de/Hero/hero-audibene-de-sales.jpg',
      desktop: 'de/Hero/hero-audibene-de-sales.jpg'
    },
    title: 'Finde deinen Traumjob',
    subtitle: 'In unserem Sales Team.',
    cta: {
      text: 'Jetzt bewerben!',
      link: 'https://boards.greenhouse.io/audibenehearcom/jobs/4706391'
    }
  },
  whatAwaits: {
    title: 'Das erwartet dich bei audibene:',
    items: [
      {
        icon: 'de/Sales/WhatAwaits/ico-intro-1.svg',
        text: 'Viele Kund:innen, die du jeden Tag mit gutem Hören glücklich machst.'
      },
      {
        icon: 'de/Sales/WhatAwaits/ico-intro-2.svg',
        text: 'Ein hochmodernes Arbeitsumfeld im Home Office oder Büro.'
      },
      {
        icon: 'de/Sales/WhatAwaits/ico-intro-3.svg',
        text: 'Tolle Kolleg:innen in einer familiären Arbeitsatmosphäre.'
      },
      {
        icon: 'de/Sales/WhatAwaits/ico-intro-4.svg',
        text: 'Ein attraktives, leistungsorientiertes Gehalts- und Bonusmodell.'
      },
      {
        icon: 'de/Sales/WhatAwaits/ico-intro-5.svg',
        text: 'Aktive Weiterentwicklung mit Trainings und Coachings.'
      }
    ]
  },
  salesJob: {
    title: 'Wie sieht der Job im Sales Team bei audibene aus?',
    blocks: [
      {
        img: {
          desktop: 'de/SalesJob/mainz-consultant_desktop.jpg',
          mobile: 'de/SalesJob/mainz-consultant_mobile.jpg'
        },
        textBlock: {
          text:
            'Der Job eines Kundenberaters oder einer Kundenberaterin bei uns ist vielfältig. Direkt beim Start erhältst du „warme“ Kontakte von Kund:innen, die sich auf unserer Website angemeldet haben.<br />Von da an begleitest du sie auf dem Weg zu besserem Hören und mehr Lebensqualität – vom ersten Kontakt bis hin zum Abschluss.<br />Inhaltlich bist du dafür verantwortlich, Kund:innen am Telefon zu beraten und zum Testen von Hörgeräten zu motivieren. Unsere größte Kund:innengruppe, die „Babyboomer“, ist dabei anspruchsvoll und erwartet eine kompetente und einfühlsame Beratung.'
        }
      },
      {
        img: {
          desktop: 'de/SalesJob/berlin-consultant_desktop.jpg',
          mobile: 'de/SalesJob/berlin-consultant_mobile.jpg'
        },
        textBlock: {
          text:
            'Nachdem du für deine Kund:innen im Erstgespräch Termine vereinbart hast, bist du dafür verantwortlich, deine eigene Kund:innen-Pipeline zu organisieren und für jeden Kunden bzw. für jede Kundin einen Plan aufzustellen. Deinen Arbeitsalltag bestimmst du dabei selbständig. Während des gesamten Prozesses kommunizierst du sowohl mit deinen Kund:innen als auch mit den entsprechenden Partnerakustikern. So stellst du sicher, dass du die optimale Lösung findest.'
        }
      }
    ]
  },
  audibeneModel: {
    title: 'Das audibene Modell: Der weltweit einzigartige Weg zum perfekten Hörgerät',
    items: [
      {
        icon: {
          color: '#A0CFEB'
        },
        text:
          'Über konsequentes Online-Marketing erreichen wir gezielt moderne, aktive Menschen mit Hörproblemen – und motivieren sie, sich zum kostenlosen Testen von Mini-Hörgeräten auf unserer Website anzumelden. Mehrere tausend neue Kund:innen registrieren sich auf diese Weise jede Woche bei uns!'
      },
      {
        icon: {
          color: '#6AADE4'
        },
        text:
          'Nach der Registrierung meldet sich ein Berater/eine Beraterin aus unserem Sales Team telefonisch beim Kunden/bei der Kundin. Bei dieser Erstberatung werden alle Fragen rund um das Thema Hörgeräte geklärt, eine Bedarfsanalyse durchgeführt und ein Termin bei einem unserer Partnerakustiker vor Ort vereinbart.'
      },
      {
        icon: {
          color: '#00549F'
        },
        text:
          'Bei unserem Partnerakustiker wird ein exakter Hörtest beim Kunden/bei der Kundin durchgeführt und ein geeignetes Hörgeräte-Modell zum Probetragen angepasst.'
      },
      {
        icon: {
          color: '#21314D'
        },
        text:
          'Jetzt kann der Kunde/die Kundin die Hörgeräte über 4 Wochen im Alltag testen. Dabei wird er oder sie regelmäßig von uns kontaktiert und beraten. Bei Bedarf wird das Hörgerätemodell gewechselt oder weiter angepasst. Diese „beidseitige“ Expert:innenberatung führt schneller und effizienter zum Hör-Erfolg, als bei anderen Hörakustikern.'
      },
      {
        icon: {
          color: '#FFA100'
        },
        text:
          'Sobald das perfekte Hörgerät gefunden ist, schließt der Kundenberater/die Kundenberaterin den Verkauf mit dem Kunden/der Kundin ab.'
      }
    ]
  },
  apply: {
    title: 'Bewerben bei audibene: In nur 5 Schritten zum Traumjob',
    text:
      'Unser Ziel ist es, die besten Vertriebler:innen in ganz Deutschland zu finden! Deshalb nehmen wir uns gerne besonders viel Zeit für dich und deine Bewerbung. Du erhältst nach jedem Schritt ein ausführliches Feedback von uns. Insgesamt solltest du ca. 2 Wochen für den gesamten Prozess einplanen.',
    cta: {
      text: 'Hier geht es zum Bewerbungsformular »',
      link: 'https://boards.greenhouse.io/audibenehearcom/jobs/2205293'
    },
    items: [
      {
        icon: {
          src: 'de/Apply/ico-application.svg'
        },
        text:
          '<strong>Bewerbung:</strong> Bewirb dich einfach und unkompliziert mit deinem Lebenslauf oder deinem XING Profil. Ein Anschreiben ist dabei nicht notwendig.'
      },
      {
        icon: {
          src: 'de/Apply/ico-phone.svg'
        },
        text:
          '<strong>Erstes Interview:</strong> Zunächst hast du ein ca. 30-minütiges telefonisches Kennenlerngespräch mit einem unserer Recruiter bzw. mit einer unserer Recruiterinnen. Dabei besprechen wir die Rolle des Customer Sales Consultants und deine bisherige Erfahrung.'
      },
      {
        icon: {
          src: 'de/Apply/ico-laptop.svg'
        },
        text:
          '<strong>Probe-Arbeiten:</strong> Danach erhältst du die Möglichkeit, hautnah dabei zu sein und einem unserer Hörexperten oder einer unserer Hörexpertinnen über die Schulter zu schauen. Natürlich funktioniert das Ganze auch über Video-Telefonie im Home Office.'
      },
      {
        icon: {
          src: 'de/Apply/ico-convo.svg'
        },
        text:
          '<strong>Zweites Interview:</strong> Zuletzt hast du ein Gespräch mit einem/r unserer Team- oder Regionalleiter:innen. Hier gehen wir besonders auf deine Vertriebserfahrung ein.'
      },
      {
        icon: {
          src: 'de/Apply/ico-contract.svg'
        },
        text:
          '<strong>Angebot und Vertragsabschluss:</strong> Alles hat geklappt. Eine/r unserer Recruiter:innen meldet sich bei dir, um dir die frohe Nachricht zu überbringen und mit dir alle Vertragsdetails zu besprechen.'
      }
    ]
  },
  applyCampaign: {
    title: 'Bewerben bei audibene: In nur 5 Schritten zum Traumjob',
    text:
      'Unser Ziel ist es, die besten Vertriebler:innen in ganz Deutschland zu finden! Deshalb nehmen wir uns gerne besonders viel Zeit für dich und deine Bewerbung. Du erhältst nach jedem Schritt ein ausführliches Feedback von uns. Insgesamt solltest du ca. 2 Wochen für den gesamten Prozess einplanen.',
    cta: {
      text: 'Hier geht es zum Bewerbungsformular »',
      link: 'https://boards.greenhouse.io/audibenehearcom/jobs/4706391'
    },
    items: [
      {
        icon: {
          src: 'de/Apply/ico-application.svg'
        },
        text:
          '<strong>Bewerbung:</strong> Bewirb dich einfach und unkompliziert mit deinem Lebenslauf oder deinem XING Profil. Ein Anschreiben ist dabei nicht notwendig.'
      },
      {
        icon: {
          src: 'de/Apply/ico-phone.svg'
        },
        text:
          '<strong>Erstes Interview:</strong> Zunächst hast du ein ca. 30-minütiges telefonisches Kennenlerngespräch mit einem unserer Recruiter bzw. mit einer unserer Recruiterinnen. Dabei besprechen wir die Rolle des Customer Sales Consultants und deine bisherige Erfahrung.'
      },
      {
        icon: {
          src: 'de/Apply/ico-laptop.svg'
        },
        text:
          '<strong>Probe-Arbeiten:</strong> Danach erhältst du die Möglichkeit, hautnah dabei zu sein und einem unserer Hörexperten oder einer unserer Hörexpertinnen über die Schulter zu schauen. Natürlich funktioniert das Ganze auch über Video-Telefonie im Home Office.'
      },
      {
        icon: {
          src: 'de/Apply/ico-convo.svg'
        },
        text:
          '<strong>Zweites Interview:</strong> Zuletzt hast du ein Gespräch mit einem/r unserer Team- oder Regionalleiter:innen. Hier gehen wir besonders auf deine Vertriebserfahrung ein.'
      },
      {
        icon: {
          src: 'de/Apply/ico-contract.svg'
        },
        text:
          '<strong>Angebot und Vertragsabschluss:</strong> Alles hat geklappt. Eine/r unserer Recruiter:innen meldet sich bei dir, um dir die frohe Nachricht zu überbringen und mit dir alle Vertragsdetails zu besprechen.'
      }
    ]
  },
  homeOffice: {
    title: 'Erfolgreich im Home Office',
    blocks: [
      {
        text:
          'Arbeiten aus dem Home Office ist bei uns seit Anfang an möglich. Mit modernster Videotelefonie-Technologie (Zoom) und Internet-basierter CRM-Software (Salesforce) bist du aus deinem Büro zuhause voll angebunden. Ca. 70 % unserer Kundenberater:innen arbeiten bereits permanent aus dem Home Office.'
      },
      {
        text:
          'Team-orientiertes Arbeiten ist uns sehr wichtig. Dazu bist du täglich mit deinen Kolleg:innen über Videotelefonie in Kontakt. Zusätzlich veranstalten wir jedes Quartal ein Teamevent und zwei bis dreimal im Jahr Partys und Ausflüge, bei denen du deine Kolleg:innen auch persönlich triffst.'
      }
    ]
  },
  carouselSales: {
    title: 'Weiterentwicklung und Coaching',
    textBlocks: [
      {
        text:
          'Für uns ist Weiterentwicklung ein wichtiger Teil unserer Firmenkultur. Alle in unserem Team möchten einen guten Job machen und sich dabei konstant verbessern.'
      },
      {
        text:
          'Das fängt mit Herausforderungen im Job an: Die Vielfältigkeit unserer Kund:innen erfordert, dass man sich immer wieder neu an verschiedene Persönlichkeiten anpassen muss und seinen Vertriebsstil weiterentwickelt. Dabei unterstützen wir dich.'
      }
    ],
    slides: [
      {
        img: {
          mobile: 'de/Coaching/coaching_1_mobile.jpg',
          desktop: 'de/Coaching/coaching_1_desktop.jpg'
        },
        title: 'Coaching und Trainings',
        text:
          'Unser Sales Academy Team aus 10 erfahrenen Sales Coaches führt regelmäßig Vertriebstrainings durch und steht dir auch im individuellen Coaching immer zur Seite.'
      },
      {
        img: {
          mobile: 'de/Coaching/coaching_1_mobile.jpg',
          desktop: 'de/Coaching/coaching_1_desktop.jpg'
        },
        title: 'Entwicklungsstufen und Rollenwechsel',
        text:
          'Als Unternehmen wachsen wir stetig. Gute Leistung zahlt sich dabei für jeden Einzelnen und jede Einzelne aus – sowohl finanziell als auch mit mehr Verantwortung: zum Beispiel mit Entwicklung zum Hörgeräte-Experten/zur Hörgeräte-Expertin, zum Team Lead oder zum Sales Coach.'
      }
    ]
  },
  audibeneKultur: {
    title: 'Die audibene Kultur: Lerne unser Team kennen',
    text:
      'Bei audibene leben wir eine besondere Firmenkultur: Eine positive Einstellung, der freundliche Umgang miteinander und Teamwork gehören zu unserem Alltag.',
    items: [
      {
        img: 'de/Kultur/portrait_01.jpg',
        innerTitle: 'Anna Stadelmann',
        innerSubtitle: 'Team Lead Customer Sales Consulting',
        innerCopy:
          '„Ich bin mittlerweile seit 3 Jahren bei audibene und freue mich auf jedes weitere. Es gibt immer Innovationen, ein gutes Team und jeder wird bei seiner persönlichen Weiterentwicklung unterstützt.”'
      },
      {
        img: 'de/Kultur/portrait_02.jpg',
        innerTitle: 'Helena Tag',
        innerSubtitle: 'Customer Sales Consultant',
        innerCopy:
          '„Bis März 2019 habe ich in meiner Heimatstadt in einem Akustik-Fachgeschäft gearbeitet. Da ich mich beruflich verändern wollte, habe ich mich für die Karriere bei audibene entschieden. Im April 2019 bin ich für meinen Traumjob nach Berlin gezogen.“'
      },
      {
        img: 'de/Kultur/portrait_03.jpg',
        innerTitle: 'Jaqueline Jaekel',
        innerSubtitle: 'Senior Customer Sales Consultant',
        innerCopy:
          '„Ich bin zu audibene gekommen, weil ich etwas machen wollte, das einen Sinn hat – nämlich Menschen zu helfen, die unsere Hilfe wollen. Ich hatte noch nie Scheu davor, auf andere Menschen einzugehen. Bei audibene kann ich so sein wie ich bin und muss mich nicht verstellen, wenn ich mit meinen Kund:innen spreche.“'
      }
    ]
  },
  salesContact: {
    title: 'Du hast noch Fragen?',
    text:
      'Wir sind jederzeit für dich da!<br />Telefonisch erreichst du uns unter der: 030 208 480 164',
    contacts: [
      {
        name: 'Sabine Fleischer',
        position: 'Talent Acquisition Managerin',
        email: 'sabine.fleischer@audibene.de',
        img: 'de/SalesContact/sabine-fleischer.jpg'
      }
    ]
  },

  //internship page

  internHero: {
    img: {
      desktop: 'de/Hero/hero-audibene-de-terrace-d.jpg',
      mobile: 'de/Hero/hero-audibene-de-terrace-m.jpg'
    },
    title: 'Become part of our team',
    subtitle: 'Shape the future of the hearing care industry with us and boost your career!',
    cta: {
      text: 'Apply now!',
      anchor: 'jobs'
    }
  },
  internWhatAwaits: {
    title: 'What awaits you at audibene?',
    items: [
      {
        icon: 'de/Values/responsibility.svg',
        title: 'Responsibility',
        text: 'Bring in your ideas and drive your own projects with full ownership.'
      },
      {
        icon: 'de/TalentProgram/party_final.svg',
        title: 'Benefits',
        text: 'Use our offers such as German classes,Tandem Lunches and Yoga.'
      },
      {
        icon: 'de/TalentProgram/holding_hands.svg',
        title: 'Team of Teams',
        text: 'Get wide-ranging insights into our business and learn from different teams.'
      },
      {
        icon: 'de/TalentProgram/globe.svg',
        title: 'International Projects',
        text: 'Work on international projects in a global healthtech company.'
      },
      {
        icon: 'de/TalentProgram/ear_final.svg',
        title: 'Internship with Purpose',
        text: "Change together with us people's lives through better hearing."
      }
    ]
  },
  aboutIntern: {
    title: 'audibene is a place to grow and to learn!',
    blocks: [
      {
        text:
          'Our interns and working students are a valuable part of the team. At audibene we make sure to enable you to make an impact on the business by working on meaningful tasks and foster personal and professional growth.'
      },
      {
        text:
          "No more theory – it's time to practice and apply your skills. You’ll be right at the heart of major company projects, working with international teams of passionate and driven people."
      }
    ]
  },
  talentProgram: {
    title: 'Get the most out of your time with us: Join our Future Talent Program!',
    blocks: [
      {
        img: {
          desktop: 'de/TalentProgram/intern_01.jpg',
          mobile: 'de/TalentProgram/intern_01.jpg'
        },
        textBlock: {
          title: 'Have real impact on the business from day one',
          list: [
            ['Meaningful Tasks & Projects'],
            ['Ownership of your own business project'],
            ['Full member of the team']
          ]
        }
      },
      {
        img: {
          desktop: 'de/TalentProgram/intern_02.jpg',
          mobile: 'de/TalentProgram/intern_02.jpg'
        },
        textBlock: {
          title: 'Develop yourself personally & professionally',
          list: [
            ['Monthly Trainings'],
            ['Your Personal Development Plan'],
            ['Regular Feedback Sessions and 1:1s'],
            ['German Classes & Tandem Lunches']
          ]
        }
      },
      {
        img: {
          desktop: 'de/TalentProgram/intern_03.jpg',
          mobile: 'de/TalentProgram/intern_03.jpg'
        },
        textBlock: {
          title: 'Get to know a global Healthtech Company',
          text:
            'Get an overview of the business and different teams via attending Lunch & Learn sessions with experienced team members'
        }
      },
      {
        img: {
          desktop: 'de/TalentProgram/intern_04.jpg',
          mobile: 'de/TalentProgram/intern_04.jpg'
        },
        textBlock: {
          title: 'Be part of a highly motivated international team',
          text: 'Build connections with broader teams by attending regular social events'
        }
      }
    ]
  },
  internCarousel: {
    title: 'What makes our internships special?',
    slides: [
      {
        img: {
          mobile: 'de/Carousel/benedikt_m.jpg',
          desktop: 'de/Carousel/benedikt_d.jpg'
        },
        title: 'Benedikt',
        subtitle:
          'First an intern in the Customer Acquisition Team, now Junior Paid Social Manager',
        text:
          '“My most memorable experience at audibene so far is my growth in general- it’s making an impact and seeing how we change lives with better hearing. As an intern, I slowly got more and more responsibility and now that I’m working full-time, I’ll have the chance to get hands-on on completely new challenges.”'
      },
      {
        img: {
          mobile: 'de/Carousel/leonie_m.jpg',
          desktop: 'de/Carousel/leonie_d.jpg'
        },
        title: 'Leonie',
        subtitle: 'Talent Acquisition intern',
        text:
          '"What makes my internship at audibene so special is that I can also work on strategic topics. I can bring in my own ideas and drive them. From day one I was given a lot of responsibility. In addition, I was directly considered and integrated as a full team member.”'
      }
    ]
  },

  internApply: {
    title: 'How we hire',
    items: [
      {
        icon: {
          src: 'de/Apply/ico-application.svg'
        },
        text:
          '<strong>Let’s find out if we are a good match</strong><br>Apply with your resume or with your LinkedIn profile. A cover letter is not required.'
      },
      {
        icon: {
          src: 'de/Apply/ico-phone.svg'
        },
        text:
          '<strong>Recruiter Call: Get to know audibene</strong><br> During this call we will talk about your experience and motivation. We will answer any questions that you may have about audibene and the role'
      },
      {
        icon: {
          src: 'de/Apply/ico-laptop.svg'
        },
        text:
          "<strong>Interviews: Meet your future team members</strong><br>This is a good opportunity to talk about your expertise and achievements. You will also find out more about the team's strategy and discuss the expectations for the role."
      },
      {
        icon: {
          src: 'de/Apply/ico-contract.svg'
        },
        text:
          '<strong>Offer call: Making things official</strong><br>That’s the last stage of the interviewing process, where we will discuss the final details and start our journey together!'
      }
    ]
  },

  internTipps: {
    title: 'Tips for applying',
    items: [
      {
        icon: {
          color: '#A0CFEB'
        },
        text:
          '<b>Be structured and clear:</b> Don‘t overextend your CV and give us an insight into your relevant experience instead (portfolio etc.). In the interview try to illustrate your points with specific examples and highlight your skills and previous experience in your structured answer.'
      },
      {
        icon: {
          color: '#6AADE4'
        },
        text:
          '<b>Make sure to inform yourself and take some time to do additional research:</b> Read more about the history of audibene and get a better idea of the way we work. Helpful resources can be our <a href="https://karriere.audibene.de/" target="_blank">career page</a>, our <a href="https://inside.audibene.de/" target="_blank">blog<a/> and our <a href="https://www.instagram.com/weareaudibene/" target="_blank">@weareaudibene</a> Instagram account.'
      },
      {
        icon: {
          color: '#00549F'
        },
        text:
          '<b>Show us your motivation to join audibene:</b> We believe that everyone should hear well to live well. Our goal is to build a team of people who share this mission and our values.'
      },
      {
        icon: {
          color: '#21314D'
        },
        text:
          '<b>We would like to get to know you:</b> Be yourself and let us know how you could enrich our team. We see hiring as a two-way street- so please ask your questions to see if we’re a good fit for you.'
      }
    ]
  },

  internFAQ: {
    title: 'Want to know more?',
    items: [
      {
        title: 'What types of roles are possible for future talents?',
        text:
          'Whatever you’re studying, or if you have just finished your degree - we have all kinds of internships to suit you: working student roles, voluntary & mandatory internships. Everything from 3-6 months. Take your pick – audibene is a place to grow and learn!'
      },
      {
        title: 'Are the internships paid?',
        text:
          'Yes! We value your contribution very much and want to appropriately reward your time and effort.'
      },
      {
        title: 'Who is eligible to take part in the future talent program?',
        text:
          'Students who are enrolled at a German university and graduates up to 1 year after graduation.'
      },
      {
        title: 'Is the internship remote or in Berlin?',
        text:
          'For most of our positions working remotely is possible. However, the door to our office in the heart of Berlin is open for you too.'
      }
    ]
  },
  internJobs: {
    title: 'Apply now!'
  },

  internContact: {
    text:
      '...or reach out to <a href="https://www.linkedin.com/in/nadja-reihofer/" target="_blank">Nadja</a> on LinkedIn!',
    contacts: [
      {
        img: 'de/TalentProgram/Nadja.jpg',
        name: 'Nadja Reihofer',
        position: 'Talent Acquisition Manager'
      }
    ]
  }
};

export default data;
