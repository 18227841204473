import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.scss';

import Header from '../Components/Header/Header';
import Home from '../Containers/Home/Home';
import Sales from '../Containers/Sales/Sales';
import SalesCampaign from '../Containers/Sales-campaign/Sales_campaign';
import FutureTalents from './FutureTalents/FutureTalents.js';
import Footer from '../Components/Footer/Footer';
import ScrollToTop from '../ScrollToTop.js';

import {
  getLangFromLocale,
  getCountryFromLocale,
  getLocaleFromCountryAndLang
} from '../Helpers/Utils';
import { i18n, checkIfLangIsAvailable } from '../Translations/Translations';

class App extends Component {
  state = {
    locale: this.props.locale,
    hamburgerOpen: false,
    deviceWidth: window.innerWidth,
    content: i18n(this.props.locale)
  };

  handleHamClick() {
    this.setState({
      hamburgerOpen: !this.state.hamburgerOpen
    });
  }

  handleNavClick(target) {
    this.smoothScroll(target);
    if (this.state.deviceWidth < 992) {
      this.handleHamClick();
    }
  }

  handleLangClick(locale) {
    this.changeLocale(locale);
    window.history.pushState({}, '', `?lang=${getCountryFromLocale(locale).toLowerCase()}`);
  }

  changeLocale(locale) {
    this.setState({
      locale: locale,
      content: i18n(locale)
    });
  }

  handleLanguage() {
    const { locale } = this.state;
    const langSetViaUrlParam = new URLSearchParams(window.location.search).get('lang');
    let lang;

    if (langSetViaUrlParam && checkIfLangIsAvailable(langSetViaUrlParam, locale)) {
      lang = langSetViaUrlParam;
    } else {
      if (navigator.languages) {
        navigator.languages.forEach(
          navLang => (lang = checkIfLangIsAvailable(navLang, locale) && navLang)
        );
      }
    }

    if (lang && lang !== getLangFromLocale(locale)) {
      const newLocale = getLocaleFromCountryAndLang(lang, getCountryFromLocale(locale));
      this.changeLocale(newLocale);
    }
  }

  smoothScroll(target) {
    var offsetTop =
      document.getElementById(target).offsetTop - (this.state.deviceWidth < 992 ? 90 : 120);
    window.scrollTo({
      top: offsetTop,
      behavior: 'smooth'
    });
  }

  componentDidMount() {
    this.handleLanguage();
    window.addEventListener('resize', this.onResize);
  }

  onResize = () => {
    this.setState({ deviceWidth: window.innerWidth });
  };

  render() {
    const {
      deviceWidth,
      locale,
      content,
      content: {
        head: { title, description, favicon }
      }
    } = this.state;

    return (
      <BrowserRouter>
        <div className="App">
          <Helmet>
            <meta name="description" content={description} />
            <title>{title}</title>
            <link rel="shortcut icon" href={favicon} />
            <script
              crossorigin="anonymous"
              src="https://polyfill.io/v3/polyfill.min.js?features=Array.prototype.forEach%2Csmoothscroll"
            ></script>
          </Helmet>
          {locale.includes('_DE') && (
            <Helmet>
              <link rel="canonical" href="https://karriere.audibene.de/" />
              <link rel="alternate" hreflang="de-DE" href="https://karriere.audibene.de/" />
              <link rel="alternate" hreflang="de-CH" href="https://www.audibene.ch/jobs/" />
              <link
                rel="alternate"
                hreflang="nl-NL"
                href="https://www.audibene.nl/over-ons/vacatures"
              />
              <script
                src="https://session.cdn.audibene.net/sessionLayer.js?v=5.16.0"
                id="SessionLayer"
                data-locale="de_DE"
                data-gtmid="GTM-WRBTKB9"
              ></script>
            </Helmet>
          )}
          {locale.includes('_US') && (
            <Helmet>
              <meta property="og:title" content="Hear.com Careers" />
              <meta property="og:site_name" content="Hear.com" />
              <meta property="og:url" content="https://careers.hear.com/" />
              <meta property="og:description" content="" />
              <meta property="og:type" content="website" />
              <meta
                property="og:image"
                content={`${process.env.PUBLIC_URL}/Assets/us/og-image.jpg`}
              />
            </Helmet>
          )}

          <Route
            path="/"
            render={props => (
              <Header
                {...props}
                isOpen={this.state.hamburgerOpen}
                navClick={this.handleNavClick.bind(this)}
                hamClick={this.handleHamClick.bind(this)}
                langClick={this.handleLangClick.bind(this)}
                deviceWidth={deviceWidth}
                locale={locale}
                content={content.header}
              />
            )}
          />
          <ScrollToTop />
          <Switch>
            <Route
              exact
              path="/sales"
              render={props => (
                <Sales {...props} deviceWidth={deviceWidth} locale={locale} content={content} />
              )}
            />
            <Route
              exact
              path="/futuretalents"
              render={props => (
                <FutureTalents
                  {...props}
                  deviceWidth={deviceWidth}
                  locale={locale}
                  content={content}
                />
              )}
            />
            <Route
              exact
              path="/salescampaign"
              render={props => (
                <SalesCampaign
                  {...props}
                  deviceWidth={deviceWidth}
                  locale={locale}
                  content={content}
                />
              )}
            />
            <Route
              render={props => (
                <Home {...props} deviceWidth={deviceWidth} locale={locale} content={content} />
              )}
            />
          </Switch>

          <Footer content={content.footer} />
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
