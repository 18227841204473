import React from 'react';
import './Accordion.scss';

const verticalList = props => {
  const {
    content: { title, items }
  } = props;

  return (
    <section className={`section section--contained`}>
      <div className="section__wrapper">
        <div className="section__head">
          <h3 className="section__title blue-underline">{title}</h3>
        </div>

        <div className="section__content">
          <div className="accordion">
            {items.map((el, i) => {
              return (
                <div key={i} className="accordion__item">
                  <details>
                    <summary
                      className="accordion__title"
                      dangerouslySetInnerHTML={{ __html: el.title }}
                    ></summary>
                    <p className="accordion__text" dangerouslySetInnerHTML={{ __html: el.text }} />
                  </details>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default verticalList;
