const data = {
  head: {
    title: 'Aktuelle Stellenangebote bei audibene',
    description:
      'Unsere Vision ist es, Menschen weltweit zu besserem Hören zu verhelfen. Werde Teil unseres bunten Teams und mache die Welt ein bisschen glücklicher.',
    favicon: 'favicon-de.ico'
  },
  benefits: {
    title: 'Benefits',
    items: [
      {
        title: 'Free fruits & drinks',
        icon: 'de/Benefits/fruits.svg',
        text:
          'Wir versorgen dich täglich mit Drinks, Müsli und Snacks. Deine Gesundheit unterstützen wir außerdem regelmäßig mit frischem Obst und knackigem Gemüse.'
      },
      {
        title: 'Fitness',
        icon: 'de/Benefits/fitness.svg',
        text:
          'Mit der audibene Gympass-Mitgliedschaft erhältst du ermäßigten Zugang zu mehr als 2.000 verschiedenen Fitness-, Sport- und Wellness-Angeboten in Deutschland.'
      },
      {
        title: 'Come as you are',
        icon: 'de/Benefits/shoe.svg',
        text:
          'Bei uns darf jeder so ins Office kommen, wie er mag. Casual in Shorts und Sneakers oder schick in Hemd und Chinos. Sei du selbst, dann fühlst du dich am wohlsten.'
      },
      {
        title: 'Startup feeling',
        icon: 'de/Benefits/rocket.svg',
        text:
          'Auch, wenn wir keins mehr sind. Einen Kicker, einen Grill, eine Dachterrasse und regelmäßige Team- und Company-Events haben wir trotzdem beibehalten.'
      }
    ]
  },
  trustwidgets: {
    items: [
      {
        url:
          'https://www.kununu.com/de/audibene1?utm_source=widget&utm_campaign=widget_selfservice_scoreandrevie',
        imgSrc: 'https://www.kununu.com/de/partner/KlpeDFU%3D/self-service-button?button-type=2',
        imgTitle: 'kununu'
      },
      {
        url: 'https://www.glassdoor.de/Überblick/Arbeit-bei-audibene-EI_IE1155737.11,19.htm',
        imgSrc: 'https://www.glassdoor.de/api/widget/verticalStarRating.htm?e=1155737',
        imgTitle: 'glassdoor'
      }
    ]
  },
  awards: {
    items: [
      {
        icon: 'de/Awards/kununu-open-company_de.png'
      },
      {
        icon: 'de/Awards/kununu-top-company_de.png'
      },
      {
        icon: 'de/Awards/glassdoor_de.png'
      }
    ]
  },
  carousel: {
    slides: [
      {
        img: {
          mobile: 'de/Carousel/miracle_m.jpg',
          desktop: 'de/Carousel/miracle.jpg'
        },
        title: 'Vom Berliner Start-up zu einem der führenden Akustiker weltweit',
        text:
          'Wir sind der am schnellsten wachsende Hörgeräte-Akustiker, den es je gab. Nach kurzer Zeit haben wir uns von Berlin aus auf zwei weitere Kontinente ausgebreitet. Heute haben wir Büros in Berlin, Mainz, Miami, Denver, Toronto, Paris, Kuala Lumpur, Delhi, Utrecht, Bern und Seoul.'
      },
      {
        img: {
          mobile: 'de/Carousel/marco_and_paul_m.jpg',
          desktop: 'de/Carousel/marco_and_paul.jpg'
        },
        title: 'Eine Vision wird geboren',
        text:
          'Unsere Gründer Dr. Marco Vietor und Paul Crusius kennen sich schon aus der Uni. Gemeinsam hatten sie die Idee, mit Vorurteilen gegenüber Hörgeräten aufzuräumen und Menschen auf ganz moderne und einfache Weise zu besserem Hören – und damit zu mehr Lebensqualität – zu verhelfen.'
      },
      {
        img: {
          mobile: 'de/Carousel/wohnzimmer_m.jpg',
          desktop: 'de/Carousel/wohnzimmer.jpg'
        },
        title: 'Alles begann in diesem Wohnzimmer',
        text:
          '2012 starteten Marco, Paul und 3 weitere Kollegen mit dem audibene Geschäftsmodell. Die ersten telefonischen Kundengespräche fanden noch im Wohnzimmer von Marco statt. Ohne Schreibtische und mit nur einer Telefonleitung.'
      },
      {
        img: {
          mobile: 'de/Carousel/koepenicker_m.jpg',
          desktop: 'de/Carousel/koepenicker.jpg'
        },
        title: 'Das erste Büro',
        text:
          'Drei Monate später bezogen sie das erste offizielle audibene Büro in Berlin Kreuzberg: Schreibtische für alle und jede Menge Platz. Ab jetzt wurden täglich immer mehr Menschen hörglücklich gemacht und das Team wuchs so stark weiter, dass es auch hier bald deutlich zu eng wurde …'
      },
      {
        img: {
          mobile: 'de/Carousel/zaudibene_m.jpg',
          desktop: 'de/Carousel/zaudibene.jpg'
        },
        title: 'Aus klein wird groß',
        text:
          'Zwei weitere Umzüge und etlichen Zuwachs an Kollegen später findet man das audibene Headquarter heute mit rund 250 Mitarbeitern in Berlin Prenzlauer Berg sowie einen weiteren Firmensitz in Mainz. Eine gewisse Wohnzimmer-Dynamik soll beibehalten werden: Marco und Paul haben keine eigenen Büros, sondern sitzen zwischen uns allen.'
      },
      {
        img: {
          mobile: 'de/Carousel/us-office_m.jpg',
          desktop: 'de/Carousel/us-office.jpg'
        },
        title: 'Höher, schneller, weiter',
        text:
          'Die Vision unserer Gründer fand Anklang. Und das nicht nur in Deutschland – auch in weiteren Teilen Europas, in den USA und in Asien möchten Menschen ihr Leben mit audibene verbessern. Heute sorgen Marco und Paul mit rund 1.200 Kollegen aus 45 Nationen für gutes und einfaches Hören in 8 Ländern der Welt. Weitere Märkte nicht ausgeschlossen.'
      }
    ]
  },
  development: {
    title: 'Entwickle dich mit uns weiter',
    text:
      'Bei uns hast du etliche Möglichkeiten, dich nach deinen Wünschen weiterzuentwickeln. Wir glauben daran, dass Menschen nur dann im Job glücklich sind, wenn ihnen genügend Freiraum zur Selbstverwirklichung geboten wird. Damit wir nicht stehen bleiben – und du es auch nicht tust – setzen wir immer wieder auf persönliche Trainings und Perspektivenwechsel.',
    items: [
      {
        title: 'Im Team',
        img: 'de/Development/portrait_01.jpg',
        innerTitle: 'Christina',
        innerSubtitle: 'Head of Customer Sales Mainz',
        innerCopy:
          '„Ich kam 2015 als Kundenberaterin zu audibene nach Berlin. Inzwischen bin ich wieder in meine geliebte Heimat zurückgezogen und leite hier eins unserer Kundenberater-Teams am schönen Rhein in Mainz.“'
      },
      {
        title: 'Teamübergreifend',
        img: 'de/Development/portrait_02.jpg',
        innerTitle: 'Moritz',
        innerSubtitle: 'Business Analyst',
        innerCopy:
          '„Als Praktikant durfte ich in ganz verschiedene Bereiche schnuppern. Daten haben mich dabei immer interessiert. Heute bin ich audibene Business Analyst – wer eine Frage zu Daten hat, kommt zu mir.“'
      },
      {
        title: 'Persönlich',
        img: 'de/Development/portrait_03.jpg',
        innerTitle: 'Frania',
        innerSubtitle: 'Sales Operations Manager',
        innerCopy:
          '„Reisen ist meine Leidenschaft – privat und beruflich. Als Coach für unsere Hörgeräte-Experten war ich schon mehrere Monate in Toronto und in Miami. Weitere Trips für audibene sind nicht ausgeschlossen.“'
      }
    ]
  },
  footer: {
    social: [
      {
        title: 'LinkedIn',
        icon: 'linkedin.svg',
        url:
          'https://www.linkedin.com/company/audibene?utm_source=Career%20Website&utm_medium=Icon&utm_campaign=Employer%20Branding'
      },
      {
        title: 'Xing',
        icon: 'xing.svg',
        url:
          'https://www.xing.com/pages/audibene?utm_source=Career%20Website&utm_medium=Icon&utm_campaign=Employer%20Branding'
      },
      {
        title: 'Instagram',
        icon: 'instagram.svg',
        url:
          'https://www.instagram.com/weareaudibene/?utm_source=Career%20Website&utm_medium=Icon&utm_campaign=Employer%20Branding'
      },
      {
        title: 'youtube',
        icon: 'youtube.svg',
        url: 'https://www.youtube.com/channel/UCgxXCeJf-rlwNQK73J2nyPw'
      },
      {
        title: 'Blog',
        icon: 'blog.svg',
        url:
          'https://inside.audibene.de/?utm_source=Career%20Website&utm_medium=Icon&utm_campaign=Employer%20Branding'
      }
    ],
    international: {
      title: 'audibene/hear.com international:',
      links: [
        {
          title: 'Netherlands',
          icon: 'flag_netherlands@2x.png',
          url: 'https://www.audibene.nl'
        },
        {
          title: 'Switzerland',
          icon: 'flag_switzerland@2x.png',
          url: 'https://www.audibene.ch'
        },
        {
          title: 'Canada',
          icon: 'flag_canada@2x.png',
          url: 'https://www.hear.com/ca'
        },
        {
          title: 'France',
          icon: 'flag_france@2x.png',
          url: 'https://www.audibene.fr/'
        },
        {
          title: 'USA',
          icon: 'flag_usa@2x.png',
          url: 'https://www.hear.com/'
        },
        {
          title: 'India',
          icon: 'IN_flag@2x.png',
          url: 'https://www.hear.com/in'
        },
        {
          title: 'Malaysia',
          icon: 'flag_malaysia@2x.png',
          url: 'https://www.hear.com/my'
        },
        {
          title: 'Germany',
          icon: 'flag_germany@2x.png',
          url: 'https://www.audibene.de/'
        },
        {
          title: 'South Korea',
          icon: 'flag_south_korea@2x.png',
          url: 'https://www.hear.com/kr'
        }
      ]
    },
    nav: [
      {
        title: 'Impressum',
        url: 'https://www.audibene.de/impressum/'
      },
      {
        title: 'AGB',
        url: 'https://www.audibene.de/agb-datenschutz/'
      },
      {
        title: 'Datenschutz',
        url: 'https://www.audibene.de/datenschutz/'
      },
      {
        title: 'Presse',
        url: 'https://www.audibene.de/presse/'
      }
    ]
  },
  header: {
    url: 'https://www.audibene.de/',
    logo: 'https://res.cloudinary.com/dhqvlsb3l/image/upload/v1/_BRAND/DE/audibene-claim.svg',
    items: [
      {
        text: 'Das sind wir',
        anchor: 'who'
      },
      {
        text: 'Benefits',
        anchor: 'benefits'
      },
      {
        text: 'Jobs',
        anchor: 'jobs'
      }
    ]
  },
  hero: {
    img: {
      mobile: 'de/Hero/hero-audibene-de.jpg',
      desktop: 'de/Hero/hero-audibene-de.jpg'
    },
    title: 'Jobs bei audibene',
    subtitle: 'Wir lassen Menschen besser hören.',
    cta: {
      text: 'Finde deinen Job',
      anchor: 'jobs'
    },
    videoId: '801217430'
  },
  jobs: {
    title: 'Jobs'
  },
  offices: {
    title: {
      mobile: 'Standorte in Deutschland',
      desktop: 'Standorte in Deutschland – Wo möchtest du arbeiten?'
    },
    items: [
      [
        {
          block: 'image',
          img: 'de/Offices/o_01.jpg'
        },
        {
          block: 'text',
          title: 'Berlin',
          text:
            'In unserem Headquarter in Berlin erwarten dich ca. 250 Kollegen, 5 Hunde und 200m² Dachterrasse. Auf 4 Etagen mit Loft-Charakter sorgen wir vom Prenzl Berg aus weltweit für gutes Hören. Hier ist der Ort, an dem die Marketing-Fäden all unserer Büros zusammenlaufen.'
        },
        {
          block: 'image',
          img: 'de/Offices/o_02.jpg'
        }
      ],
      [
        {
          block: 'image',
          img: 'de/Offices/o_03.jpg'
        },
        {
          block: 'image',
          img: 'de/Offices/o_04.jpg'
        },
        {
          block: 'text',
          title: 'Mainz',
          text:
            'Im RheinCampus in Mainz vereinen wir unser internationales Schulungszentrum für unsere Hörberater, ein Hörakustik-Studio mit modernster Technik und Büroräume unserer Kundenberater-Teams – alles mit direktem Blick auf den wunderschönen Rhein.'
        }
      ],
      [
        {
          block: 'text',
          title: 'Dein Home Office',
          titleMobile: 'Home Office',
          text:
            'Zu Hause ists doch am schönsten? Bei uns hast du die Möglichkeit, deutschlandweit aus deinem Home Office zu arbeiten. Dank modernster Video-Kommunikationstechnik bist du zwar bequem bei dir zu Hause, fühlst dich deinen Kollegen aber trotzdem ganz nah.'
        },
        {
          block: 'image',
          img: 'de/Offices/o_05.jpg'
        },
        {
          block: 'image',
          img: 'de/Offices/o_06.jpg'
        }
      ]
    ],
    single: {
      title: 'Wähle dein Traum-Büro',
      text:
        'Wir bieten dir die Möglichkeit, deutschlandweit mit uns zu arbeiten. Du entscheidest dich zwischen: Großstadtluft in unserem loftartigen Headquarter in Berlin, Blick auf den Rhein in unserem Office im idyllischen Mainz oder deinen ganz eigenen vier Wänden bei dir zu Hause.'
    }
  },
  values: {
    title: 'Unsere Werte',
    items: [
      {
        title: 'Menschen helfen',
        icon: 'de/Values/change-lives.svg',
        text: 'Wir verändern das Leben der Menschen durch besseres Hören. Das treibt uns an.'
      },
      {
        title: 'Gemeinsam als Team',
        icon: 'de/Values/one-team.svg',
        text: 'Wir arbeiten wirklich gerne zusammen. Wir haben Spaß und lieben es zu feiern.'
      },
      {
        title: 'Ideen umsetzen',
        icon: 'de/Values/entrepreneurs.svg',
        text: 'Wir sind Unternehmer. Wir handeln schnell und gehen auch mal Risiken ein.'
      },
      {
        title: 'Wachstum',
        icon: 'de/Values/growth.svg',
        text: 'Wir legen Wert auf Wachstum: Persönliches, Team- und Kundenwachstum.'
      },
      {
        title: 'Verantwortung',
        icon: 'de/Values/responsibility.svg',
        text: 'Du hast die Verantwortung. Wir haben kein Organigramm.'
      }
    ]
  },
  who: {
    title: 'Das sind wir',
    text:
      'Unsere Vision ist es, Menschen weltweit zu besserem Hören zu verhelfen. Dank modernster Technik in nahezu unsichtbarem Design muss heute niemand mehr mit schlechtem Hören leben. Werde Teil unseres bunten Teams und mache die Welt ein bisschen glücklicher.',
    success: {
      title: 'Unsere Erfolgsgeschichte',
      items: [
        {
          number: '2012',
          text: 'Gründung'
        },
        {
          number: '8',
          text: 'Länder'
        },
        {
          number: '1.200',
          text: 'Kollegen'
        },
        {
          number: '45',
          text: 'Nationen'
        }
      ]
    }
  },
  work: {
    title: 'Wie wir arbeiten',
    blocks: [
      {
        img: {
          desktop: 'de/Work/presentation.jpg',
          mobile: 'de/Work/presentation-mobile.jpg'
        },
        textBlock: {
          title: 'One team, one dream',
          text:
            'Wir arbeiten teamübergreifend an Projekten und denken nicht in Abteilungen. Hierarchien spielen bei uns kaum eine Rolle. Stattdessen kommunizieren wir auf Augenhöhe und schätzen jede Meinung – egal, ob vom Praktikanten oder vom Teamleiter.'
        }
      }
    ]
  },
  video: {
    videoId: '801217430',
    playImg: 'de/Video/play.svg',
    coverImg: 'de/Video/video-2023-cover.jpg'
  },
  // sales page
  salesHero: {
    img: {
      mobile: 'de/Hero/hero-audibene-de-sales.jpg',
      desktop: 'de/Hero/hero-audibene-de-sales.jpg'
    },
    title: 'Finde deinen Traumjob',
    subtitle: 'In unserem Sales Team.',
    cta: {
      text: 'Jetzt bewerben!',
      link: 'https://boards.greenhouse.io/audibenehearcom/jobs/2205293'
    }
  },
  // sales campaign page
  salesCampaignHero: {
    img: {
      mobile: 'de/Hero/hero-audibene-de-sales.jpg',
      desktop: 'de/Hero/hero-audibene-de-sales.jpg'
    },
    title: 'Finde deinen Traumjob',
    subtitle: 'In unserem Sales Team.',
    cta: {
      text: 'Jetzt bewerben!',
      link: 'https://boards.greenhouse.io/audibenehearcom/jobs/4706391'
    }
  },
  whatAwaits: {
    title: 'Das erwartet dich bei audibene:',
    items: [
      {
        icon: 'de/Sales/WhatAwaits/ico-intro-1.svg',
        text: 'Viele Kund:innen, die du jeden Tag mit gutem Hören glücklich machst.'
      },
      {
        icon: 'de/Sales/WhatAwaits/ico-intro-2.svg',
        text: 'Ein hochmodernes Arbeitsumfeld im Home Office oder Büro.'
      },
      {
        icon: 'de/Sales/WhatAwaits/ico-intro-3.svg',
        text: 'Tolle Kolleg:innen in einer familiären Arbeitsatmosphäre.'
      },
      {
        icon: 'de/Sales/WhatAwaits/ico-intro-4.svg',
        text: 'Ein attraktives, leistungsorientiertes Gehalts- und Bonusmodell.'
      },
      {
        icon: 'de/Sales/WhatAwaits/ico-intro-5.svg',
        text: 'Aktive Weiterentwicklung mit Trainings und Coachings.'
      }
    ]
  },
  salesJob: {
    title: 'Wie sieht der Job im Sales Team bei audibene aus?',
    blocks: [
      {
        img: {
          desktop: 'de/SalesJob/mainz-consultant_desktop.jpg',
          mobile: 'de/SalesJob/mainz-consultant_mobile.jpg'
        },
        textBlock: {
          text:
            'Der Job eines Kundenberaters oder einer Kundenberaterin bei uns ist vielfältig. Direkt beim Start erhältst du „warme“ Kontakte von Kund:innen, die sich auf unserer Website angemeldet haben.<br />Von da an begleitest du sie auf dem Weg zu besserem Hören und mehr Lebensqualität – vom ersten Kontakt bis hin zum Abschluss.<br />Inhaltlich bist du dafür verantwortlich, Kund:innen am Telefon zu beraten und zum Testen von Hörgeräten zu motivieren. Unsere größte Kund:innengruppe, die „Babyboomer“, ist dabei anspruchsvoll und erwartet eine kompetente und einfühlsame Beratung.'
        }
      },
      {
        img: {
          desktop: 'de/SalesJob/berlin-consultant_desktop.jpg',
          mobile: 'de/SalesJob/berlin-consultant_mobile.jpg'
        },
        textBlock: {
          text:
            'Nachdem du für deine Kund:innen im Erstgespräch Termine vereinbart hast, bist du dafür verantwortlich, deine eigene Kund:innen-Pipeline zu organisieren und für jeden Kunden bzw. für jede Kundin einen Plan aufzustellen. Deinen Arbeitsalltag bestimmst du dabei selbständig. Während des gesamten Prozesses kommunizierst du sowohl mit deinen Kund:innen als auch mit den entsprechenden Partnerakustikern. So stellst du sicher, dass du die optimale Lösung findest.'
        }
      }
    ]
  },
  audibeneModel: {
    title: 'Das audibene Modell: Der weltweit einzigartige Weg zum perfekten Hörgerät',
    items: [
      {
        icon: {
          color: '#A0CFEB'
        },
        text:
          'Über konsequentes Online-Marketing erreichen wir gezielt moderne, aktive Menschen mit Hörproblemen – und motivieren sie, sich zum kostenlosen Testen von Mini-Hörgeräten auf unserer Website anzumelden. Mehrere tausend neue Kund:innen registrieren sich auf diese Weise jede Woche bei uns!'
      },
      {
        icon: {
          color: '#6AADE4'
        },
        text:
          'Nach der Registrierung meldet sich ein Berater/eine Beraterin aus unserem Sales Team telefonisch beim Kunden/bei der Kundin. Bei dieser Erstberatung werden alle Fragen rund um das Thema Hörgeräte geklärt, eine Bedarfsanalyse durchgeführt und ein Termin bei einem unserer Partnerakustiker vor Ort vereinbart.'
      },
      {
        icon: {
          color: '#00549F'
        },
        text:
          'Bei unserem Partnerakustiker wird ein exakter Hörtest beim Kunden/bei der Kundin durchgeführt und ein geeignetes Hörgeräte-Modell zum Probetragen angepasst.'
      },
      {
        icon: {
          color: '#21314D'
        },
        text:
          'Jetzt kann der Kunde/die Kundin die Hörgeräte über 4 Wochen im Alltag testen. Dabei wird er oder sie regelmäßig von uns kontaktiert und beraten. Bei Bedarf wird das Hörgerätemodell gewechselt oder weiter angepasst. Diese „beidseitige“ Expert:innenberatung führt schneller und effizienter zum Hör-Erfolg, als bei anderen Hörakustikern.'
      },
      {
        icon: {
          color: '#FFA100'
        },
        text:
          'Sobald das perfekte Hörgerät gefunden ist, schließt der Kundenberater/die Kundenberaterin den Verkauf mit dem Kunden/der Kundin ab.'
      }
    ]
  },
  apply: {
    title: 'Bewerben bei audibene: In nur 5 Schritten zum Traumjob',
    text:
      'Unser Ziel ist es, die besten Vertriebler:innen in ganz Deutschland zu finden! Deshalb nehmen wir uns gerne besonders viel Zeit für dich und deine Bewerbung. Du erhältst nach jedem Schritt ausführliches Feedback von uns. Insgesamt solltest du ca. 2 Wochen für den gesamten Prozess einplanen.',
    cta: {
      text: 'Hier geht es zum Bewerbungsformular »',
      link: 'https://boards.greenhouse.io/audibenehearcom/jobs/2205293'
    },
    items: [
      {
        icon: {
          src: 'de/Apply/ico-application.svg'
        },
        text:
          '<strong>Bewerbung:</strong> Bewirb dich einfach und unkompliziert mit deinem Lebenslauf oder deinem XING Profil. Ein Anschreiben ist dabei nicht notwendig.'
      },
      {
        icon: {
          src: 'de/Apply/ico-phone.svg'
        },
        text:
          '<strong>Erstes Interview:</strong> Zunächst hast du ein ca. 30-minütiges telefonisches Kennenlerngespräch mit einem unserer Recruiter bzw. mit einer unserer Recruiterinnen. Dabei besprechen wir die Rolle des Customer Sales Consultants und deine bisherige Erfahrung.'
      },
      {
        icon: {
          src: 'de/Apply/ico-laptop.svg'
        },
        text:
          '<strong>Probe-Arbeiten:</strong> Danach erhältst du die Möglichkeit, hautnah dabei zu sein und einem unserer Hörexperten oder einer unserer Hörexpertinnen über die Schulter zu schauen. Natürlich funktioniert das Ganze auch über Video-Telefonie im Home Office.'
      },
      {
        icon: {
          src: 'de/Apply/ico-convo.svg'
        },
        text:
          '<strong>Zweites Interview:</strong> Zuletzt hast du ein Gespräch mit einem/r unserer Team- oder Regionalleiter:innen. Hier gehen wir besonders auf deine Vertriebserfahrung ein.'
      },
      {
        icon: {
          src: 'de/Apply/ico-contract.svg'
        },
        text:
          '<strong>Angebot und Vertragsabschluss:</strong> Alles hat geklappt. Eine/r unserer Recruiter:innen meldet sich bei dir, um dir die frohe Nachricht zu überbringen und mit dir alle Vertragsdetails zu besprechen.'
      }
    ]
  },
  applyCampaign: {
    title: 'Bewerben bei audibene: In nur 5 Schritten zum Traumjob',
    text:
      'Unser Ziel ist es, die besten Vertriebler:innen in ganz Deutschland zu finden! Deshalb nehmen wir uns gerne besonders viel Zeit für dich und deine Bewerbung. Du erhältst nach jedem Schritt ausführliches Feedback von uns. Insgesamt solltest du ca. 2 Wochen für den gesamten Prozess einplanen.',
    cta: {
      text: 'Hier geht es zum Bewerbungsformular »',
      link: 'https://boards.greenhouse.io/audibenehearcom/jobs/4706391'
    },
    items: [
      {
        icon: {
          src: 'de/Apply/ico-application.svg'
        },
        text:
          '<strong>Bewerbung:</strong> Bewirb dich einfach und unkompliziert mit deinem Lebenslauf oder deinem XING Profil. Ein Anschreiben ist dabei nicht notwendig.'
      },
      {
        icon: {
          src: 'de/Apply/ico-phone.svg'
        },
        text:
          '<strong>Erstes Interview:</strong> Zunächst hast du ein ca. 30-minütiges telefonisches Kennenlerngespräch mit einem unserer Recruiter bzw. mit einer unserer Recruiterinnen. Dabei besprechen wir die Rolle des Customer Sales Consultants und deine bisherige Erfahrung.'
      },
      {
        icon: {
          src: 'de/Apply/ico-laptop.svg'
        },
        text:
          '<strong>Probe-Arbeiten:</strong> Danach erhältst du die Möglichkeit, hautnah dabei zu sein und einem unserer Hörexperten oder einer unserer Hörexpertinnen über die Schulter zu schauen. Natürlich funktioniert das Ganze auch über Video-Telefonie im Home Office.'
      },
      {
        icon: {
          src: 'de/Apply/ico-convo.svg'
        },
        text:
          '<strong>Zweites Interview:</strong> Zuletzt hast du ein Gespräch mit einem/r unserer Team- oder Regionalleiter:innen. Hier gehen wir besonders auf deine Vertriebserfahrung ein.'
      },
      {
        icon: {
          src: 'de/Apply/ico-contract.svg'
        },
        text:
          '<strong>Angebot und Vertragsabschluss:</strong> Alles hat geklappt. Eine/r unserer Recruiter:innen meldet sich bei dir, um dir die frohe Nachricht zu überbringen und mit dir alle Vertragsdetails zu besprechen.'
      }
    ]
  },
  homeOffice: {
    title: 'Erfolgreich im Home Office',
    blocks: [
      {
        text:
          'Arbeiten aus dem Home Office ist bei uns seit Anfang an möglich. Mit modernster Videotelefonie-Technologie (Zoom) und Internet-basierter CRM-Software (Salesforce) bist du aus deinem Büro zuhause voll angebunden. Ca. 70 % unserer Kundenberater:innen arbeiten bereits permanent aus dem Home Office.'
      },
      {
        text:
          'Team-orientiertes Arbeiten ist uns sehr wichtig. Dazu bist du täglich mit deinen Kolleg:innen über Videotelefonie in Kontakt. Zusätzlich veranstalten wir jedes Quartal ein Teamevent und zwei bis dreimal im Jahr Partys und Ausflüge, bei denen du deine Kolleg:innen auch persönlich triffst.'
      }
    ]
  },
  carouselSales: {
    title: 'Weiterentwicklung und Coaching',
    textBlocks: [
      {
        text:
          'Für uns ist Weiterentwicklung ein wichtiger Teil unserer Firmenkultur. Alle in unserem Team möchten einen guten Job machen und sich dabei konstant verbessern.'
      },
      {
        text:
          'Das fängt mit Herausforderungen im Job an: Die Vielfältigkeit unserer Kund:innen erfordert, dass man sich immer wieder neu an verschiedene Persönlichkeiten anpassen muss und seinen Vertriebsstil weiterentwickelt. Dabei unterstützen wir dich.'
      }
    ],
    slides: [
      {
        img: {
          mobile: 'de/Coaching/coaching_1_mobile.jpg',
          desktop: 'de/Coaching/coaching_1_desktop.jpg'
        },
        title: 'Coaching und Trainings',
        text:
          'Unser Sales Academy Team aus 10 erfahrenen Sales Coaches führt regelmäßig Vertriebstrainings durch und steht dir auch im individuellen Coaching immer zur Seite.'
      },
      {
        img: {
          mobile: 'de/Coaching/coaching_1_mobile.jpg',
          desktop: 'de/Coaching/coaching_1_desktop.jpg'
        },
        title: 'Entwicklungsstufen und Rollenwechsel',
        text:
          'Als Unternehmen wachsen wir stetig. Gute Leistung zahlt sich dabei für jeden Einzelnen und jede Einzelne aus – sowohl finanziell als auch mit mehr Verantwortung: zum Beispiel mit Entwicklung zum Hörgeräte-Experten/zur Hörgeräte-Expertin, zum Team Lead oder zum Sales Coach.'
      }
    ]
  },
  audibeneKultur: {
    title: 'Die audibene Kultur: Lerne unser Team kennen',
    text:
      'Bei audibene leben wir eine besondere Firmenkultur: Eine positive Einstellung, der freundliche Umgang miteinander und Teamwork gehören zu unserem Alltag.',
    items: [
      {
        img: 'de/Kultur/portrait_01.jpg',
        innerTitle: 'Anna Stadelmann',
        innerSubtitle: 'Team Lead Customer Sales Consulting',
        innerCopy:
          '„Ich bin mittlerweile seit 3 Jahren bei audibene und freue mich auf jedes weitere. Es gibt immer Innovationen, ein gutes Team und jeder wird bei seiner persönlichen Weiterentwicklung unterstützt.”'
      },
      {
        img: 'de/Kultur/portrait_02.jpg',
        innerTitle: 'Helena Tag',
        innerSubtitle: 'Customer Sales Consultant',
        innerCopy:
          '„Bis März 2019 habe ich in meiner Heimatstadt in einem Akustik-Fachgeschäft gearbeitet. Da ich mich beruflich verändern wollte, habe ich mich für die Karriere bei audibene entschieden. Im April 2019 bin ich für meinen Traumjob nach Berlin gezogen.“'
      },
      {
        img: 'de/Kultur/portrait_03.jpg',
        innerTitle: 'Jaqueline Jaekel',
        innerSubtitle: 'Senior Customer Sales Consultant',
        innerCopy:
          '„Ich bin zu audibene gekommen, weil ich etwas machen wollte, das einen Sinn hat – nämlich Menschen zu helfen, die unsere Hilfe wollen. Ich hatte noch nie Scheu davor, auf andere Menschen einzugehen. Bei audibene kann ich so sein wie ich bin und muss mich nicht verstellen, wenn ich mit meinen Kund:innen spreche.“'
      }
    ]
  },
  salesContact: {
    title: 'Du hast noch Fragen?',
    text:
      'Wir sind jederzeit für dich da!<br />E-Mail: jobs@audibene.de<br />Telefonisch erreichst du uns unter der: 030 208 480 164',
    contacts: []
  },

  //internship page
  internHero: {
    img: {
      desktop: 'de/Hero/hero-audibene-de-terrace-d.jpg',
      mobile: 'de/Hero/hero-audibene-de-terrace-m.jpg'
    },
    title: 'Werde Teil unseres Teams',
    subtitle: 'Verändere mit uns die Zukunft des Hörens und booste deine Karriere!',
    cta: {
      text: 'Jetzt bewerben!',
      anchor: 'jobs'
    }
  },
  internWhatAwaits: {
    title: 'Was erwartet dich bei uns?',
    items: [
      {
        icon: 'de/Values/responsibility.svg',
        title: 'Eigenverantwortung',
        text:
          'Nimm die Dinge selbst in die Hand und trage die Verantwortung für deine eigenen Projekte.'
      },
      {
        icon: 'de/TalentProgram/party_final.svg',
        title: 'Benefits',
        text: 'Profitiere von Angeboten wie Deutschkursen, Tandem Lunches und Yoga Sessions.'
      },
      {
        icon: 'de/TalentProgram/holding_hands.svg',
        title: 'Team of Teams',
        text: 'Erhalte umfassende Einblicke in unser Business und lerne von verschiedenen Teams.'
      },
      {
        icon: 'de/TalentProgram/globe.svg',
        title: 'Tolle Projekte',
        text: 'Arbeite an internationalen Projekten in einem globalen Healthtech Unternehmen.'
      },
      {
        icon: 'de/TalentProgram/ear_final.svg',
        title: 'Praktikum mit Sinn',
        text: 'Verändere mit uns gemeinsam das Leben von Menschen durch besseres Hören.'
      }
    ]
  },
  aboutIntern: {
    title: 'audibene ist ein Ort zum Wachsen & Lernen',
    blocks: [
      {
        text:
          'Unsere Praktikant:innen und Werkstudent:innen sind ein wertvoller Teil unseres Teams. Bei audibene stellen wir sicher, dass du durch die Arbeit an sinnvollen Aufgaben einen Einfluss auf das Unternehmen hast und fördern deine persönliche und deine berufliche Entwicklung.'
      },
      {
        text:
          'Schluss mit der Theorie – jetzt ist es an der Zeit, deine Fähigkeiten zu zeigen und diese in die Praxis umzusetzen. Du wirst mitten im Zentrum wichtiger Unternehmensprojekte stehen und mit einem motivierten, internationalen Teams zusammenarbeiten.'
      }
    ]
  },

  talentProgram: {
    title: 'Hol das Beste aus dir heraus: Werde Teil unseres Future Talent Programms!',
    blocks: [
      {
        img: {
          desktop: 'de/TalentProgram/intern_01.jpg',
          mobile: 'de/TalentProgram/intern_01.jpg'
        },
        textBlock: {
          title: 'Habe von Tag 1 an Einfluss auf unser Business',
          list: [
            ['Sinnvolle Aufgaben & Projekte'],
            ['Viel Eigenverantwortung'],
            ['Vollwertiges Team-Mitglied']
          ]
        }
      },
      {
        img: {
          desktop: 'de/TalentProgram/intern_02.jpg',
          mobile: 'de/TalentProgram/intern_02.jpg'
        },
        textBlock: {
          title: 'Entwickle dich persönlich & professionell weiter',
          list: [
            ['Monatlich stattfindende Trainings'],
            ['Dein persönlicher Entwicklungsplan'],
            ['Regelmäßiges Feedback auf Augenhöhe'],
            ['Deutschkurse & Tandem Lunches']
          ]
        }
      },
      {
        img: {
          desktop: 'de/TalentProgram/intern_03.jpg',
          mobile: 'de/TalentProgram/intern_03.jpg'
        },
        textBlock: {
          title: 'Lerne ein globales Healthtech Unternehmen kennen',
          text:
            'Durch die Teilnahme an gemeinsamen Lunch & Learning Sessions mit erfahrenen Team-Mitgliedern erhältst du einen umfangreichen Einblick in das Unternehmen und in den Arbeitsalltag verschiedener Teams.'
        }
      },
      {
        img: {
          desktop: 'de/TalentProgram/intern_04.jpg',
          mobile: 'de/TalentProgram/intern_04.jpg'
        },
        textBlock: {
          title: 'Werde Teil eines hochmotivierten, internationalen Teams',
          text:
            'Interessante Veranstaltungen und zahlreiche Events helfen dir dabei, schnell Kontakte zu knüpfen und dich mit anderen Teams auszutauschen.'
        }
      }
    ]
  },

  internCarousel: {
    title: 'Was macht ein Praktikum bei uns so besonders?',
    slides: [
      {
        img: {
          mobile: 'de/Carousel/benedikt_m.jpg',
          desktop: 'de/Carousel/benedikt_d.jpg'
        },
        title: 'Benedikt',
        subtitle: 'Erst Praktikant im Customer Acquisition Team, jetzt Junior Paid Social Manager',
        text:
          '„Was ich aus meinem Praktikum mitnehme, ist vor allem mein persönliches Wachstum. Bei audibene geht es darum, etwas zu bewirken und miterleben zu können, wie wir durch besseres Hören das Leben vieler Menschen positiv verändern. Als Praktikant habe ich langsam immer mehr Verantwortung übertragen bekommen, und in meiner Junior Position jetzt freue ich mich über die tolle Chance, neue Herausforderungen zu meistern.“'
      },
      {
        img: {
          mobile: 'de/Carousel/leonie_m.jpg',
          desktop: 'de/Carousel/leonie_d.jpg'
        },
        title: 'Leonie',
        subtitle: 'Praktikantin im Talent Acquisition Team',
        text:
          '„Was das Praktikum bei audibene für mich persönlich so besonders macht ist, dass ich unter anderem an strategischen Themen mitarbeiten kann. Ich kann eigene Ideen einbringen und diese auch umsetzen. Ich habe sehr schnell viel Verantwortung übertragen bekommen. Außerdem wurde ich seit dem ersten Tag als vollwertiges Teammitglied angesehen und sofort integriert.“'
      }
    ]
  },
  internApply: {
    title: 'Unser Bewerbungsprozess',
    items: [
      {
        icon: {
          src: 'de/Apply/ico-application.svg'
        },
        text:
          '<strong>Lass uns gemeinsam herausfinden, ob wir gut zusammenpassen</strong><br>Bewirb dich mit deinem Lebenslauf oder deinem LinkedIn Profil. Ein Anschreiben ist nicht nötig.'
      },
      {
        icon: {
          src: 'de/Apply/ico-phone.svg'
        },
        text:
          '<strong>Recruiter Gespräch: Lerne audibene kennen</strong><br>In diesem ersten Gespräch werden wir über deine Erfahrung und Motivation sprechen. Wir beantworten all deine Fragen rund um audibene und zu der Position, auf die du dich bewirbst.'
      },
      {
        icon: {
          src: 'de/Apply/ico-laptop.svg'
        },
        text:
          '<strong>Interviews: Triff deine zukünftigen Teammitglieder:innen</strong><br>Dieses Gespräch ist eine tolle Möglichkeit, uns dein Interesse und Können zu zeigen! Du wirst außerdem mehr über dein Team erfahren, die Themen, an denen wir gemeinsam arbeiten und unsere Erwartungen, bzw. Wünsche, die wir an dich haben.'
      },
      {
        icon: {
          src: 'de/Apply/ico-contract.svg'
        },
        text:
          '<strong>Offer Call: Lass uns die Dinge offiziell machen</strong><br>Dies ist der letzte Schritt in unserem Prozess. Hier diskutieren wir die finalen Details , finden heraus, ob es für beide Seiten passt, und starten unseren gemeinsamen Weg!'
      }
    ]
  },
  internTipps: {
    title: 'Tipps für deine Bewerbung',
    items: [
      {
        icon: {
          color: '#A0CFEB'
        },
        text:
          '<b>Achte auf Struktur & klare Formulierungen:</b> Ziehe deinen Lebenslauf nicht in die Länge und gib uns stattdessen lieber einen Einblick in deine relevante Erfahrung (Portfolio etc.). Versuche im Gespräch deine Punkte mit Beispielen zu illustrieren und hebe deine Fähigkeiten, sowie deine bisherige Erfahrung in deiner strukturierten Antwort hervor.'
      },
      {
        icon: {
          color: '#6AADE4'
        },
        text:
          '<b>Informiere dich über audibene:</b> Recherchiere zur Geschichte von audibene und bekomme ein besseres Verständnis dafür, wie wir arbeiten. Nützliche Quellen hierfür sind unsere <a href="https://karriere.audibene.de/?lang=de" target="_blank">Karriereseite</a>, unser <a href="https://inside.audibene.de/" target="_blank">Blog</a> und unser <a href="https://www.instagram.com/weareaudibene/" target="_blank">@weareaudibene</a> Instagram account.'
      },
      {
        icon: {
          color: '#00549F'
        },
        text:
          '<b>Zeig uns deine Motivation, Teil von audibene zu werden:</b> Wir sind davon überzeugt, dass gutes Hören glücklich macht. Unser Ziel ist es, ein Team aufzubauen, das diese Mission und unsere Werte teilt.'
      },
      {
        icon: {
          color: '#21314D'
        },
        text:
          '<b>Wir möchten dich kennenlernen:</b> Zeig uns, wer du bist und verrate uns, auf welche Weise du unser Team bereichern kannst. Wir sehen Hiring nicht als Einbahnstraße – habe daher keine Scheu, uns deine Fragen zu stellen und finde heraus, ob auch wir zu dir passen.'
      }
    ]
  },

  internFAQ: {
    title: 'Willst du noch mehr wissen?',
    items: [
      {
        title: 'Welche Stellen gibt es für Future Talents?',
        text:
          'Egal, was du studierst, oder ob du gerade deinen Abschluss gemacht hast — wir haben alle Arten von Praktika im Angebot: Positionen als Werkstudent:in, freiwilliges Praktikum, genauso wie Pflichtpraktika. Jeweils von 3-6 Monaten. Such Dir etwas aus  — audibene ist der Ort zum Wachsen und Lernen!'
      },
      {
        title: 'Ist ein Praktikum bei audibene bezahlt?',
        text:
          'Ja! Da wir deinen Beitrag sehr schätzen, belohnen wir diesen auch mit einem angemessenen Gehalt.'
      },
      {
        title: 'Wer kann am Future Talent Programm teilnehmen?',
        text:
          'Studierende, die an einer deutschen Universität eingeschrieben sind und Absolvent:innen bis zu einem Jahr nach dem Abschluss.'
      },
      {
        title: 'Findet das Praktikum remote oder in Berlin statt?',
        text:
          'Einen Großteil unserer Positionen können wir auch remote, also im Home Office anbieten. Genauso steht dir die Tür zu unserem wunderschönen Büro im Herzen Berlins mit großer Dachterrasse offen.'
      }
    ]
  },
  internJobs: {
    title: 'Bewirb dich jetzt!'
  },
  internContact: {
    text:
      '… oder melde dich bei <a href="https://www.linkedin.com/in/nadja-reihofer/" target="_blank">Nadja</a> auf LinkedIn!',
    contacts: [
      {
        img: 'de/TalentProgram/Nadja.jpg',
        name: 'Nadja Reihofer',
        position: 'Talent Acquisition Manager'
      }
    ]
  }
};

export default data;
