import React from 'react';

const jobsList = props => {
  const { department } = props;

  const jobList = department.jobs.map(job => {
    if (!job.hidden) {
      return (
        <a
          key={job.id}
          href={job.absolute_url}
          target="_blank"
          rel="noopener noreferrer"
          className={job.hidden ? 'job job--hidden' : 'job'}
        >
          <div className="job__name">
            {job.title.length < 60 ? job.title : job.title.substring(0, 58) + '...'}
          </div>
          <div className="job__location">{job.location.name}</div>
        </a>
      );
    }
  });

  return <div className="jobs__list">{jobList}</div>;
};

export default jobsList;
