import React from 'react';

import Hero from '../../Components/Hero/Hero';
import BigText from '../../Components/BigText/BigText';
import Success from '../../Components/Success/Success';
import Carousel from '../../Components/Carousel/Carousel';
import IconsText from '../../Components/IconsText/IconsText';
import ImageText from '../../Components/ImageText/ImageText';
import Offices from '../../Components/Offices/Offices';
import Trust from '../../Components/Trust/Trust';
import TrustWidgets from '../../Components/TrustWidgets/TrustWidgets';
import Video from '../../Components/Video/Video';
import BlocksCarousel from '../../Components/BlocksCarousel/BlocksCarousel';
import Jobs from '../../Components/Jobs/Jobs';
import Awards from '../../Components/Awards/Awards';

const home = props => {
  const { content, deviceWidth, locale } = props;

  return (
    <>
      <Hero deviceWidth={deviceWidth} content={content.hero} />
      <BigText id="who" content={content.who}>
        <Success content={content.who} />
      </BigText>
      <Carousel deviceWidth={deviceWidth} content={content.carousel} />
      <IconsText deviceWidth={deviceWidth} content={content.values} />
      <ImageText deviceWidth={deviceWidth} content={content.work} />
      <Offices deviceWidth={deviceWidth} content={content.offices} />
      {locale !== 'en_US' && <Video deviceWidth={deviceWidth} content={content.video} />}
      <BlocksCarousel id="development" deviceWidth={deviceWidth} content={content.development} />
      <IconsText
        deviceWidth={deviceWidth}
        content={content.benefits}
        customClass="section--grey"
        id="benefits"
      >
        {locale === 'en_US' ? (
          <Trust deviceWidth={deviceWidth} content={content.trust} />
        ) : (
          <>
            <TrustWidgets content={content.trustwidgets} />
            <Awards content={content.awards} />
          </>
        )}
      </IconsText>
      <Jobs locale={locale} content={content.jobs} />
    </>
  );
};

export default home;
