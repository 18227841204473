import React from 'react';
import './Contact.scss';

const contact = props => {
  const assets = process.env.PUBLIC_URL + '/Assets/';
  const {
    content: { title, text, contacts },
    containerClass = ''
  } = props;
  const itemCount = contacts.length;

  return (
    <section className={`section section--contact ${containerClass}`}>
      <div className="section__wrapper">
        <div className="section__head">
          {title && (
            <h2 className="section__title">
              <b>{title}</b>
            </h2>
          )}
        </div>

        <div className="section__content">
          <p className="section__text" dangerouslySetInnerHTML={{ __html: text }} />
          <div className="contact">
            {contacts.map((el, i) => {
              return (
                <div key={i} className={`contact__item contact__item--count-${itemCount}`}>
                  <img alt="" className="contact__img" src={assets + el.img} />
                  <div className="contact__details">
                    {el.name && <span className="contact__name">{el.name}</span>}
                    {el.position && <span className="contact__position">{el.position}</span>}
                    {el.email && (
                      <a href={`mailto:${el.email}`} className="contact__email">
                        {el.email}
                      </a>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default contact;
