import React from 'react';

import Hero from '../../Components/Hero/Hero';
import SmallText from '../../Components/SmallText/SmallText';
import Carousel from '../../Components/Carousel/Carousel';
import ImageText from '../../Components/ImageText/ImageText';
import VerticalList from '../../Components/VerticalList/VerticalList';
import Accordion from '../../Components/Accordion/Accordion';
// import Contact from '../../Components/Contact/Contact';
import Jobs from '../../Components/Jobs/Jobs';
import IconsText from '../../Components/IconsText/IconsText';

const interns = props => {
  const { content, deviceWidth, locale } = props;

  return (
    <>
      <Hero deviceWidth={deviceWidth} content={content.internHero} containerClass="talentProgram" />
      <IconsText
        deviceWidth={deviceWidth}
        content={content.internWhatAwaits}
        containerClass="talentProgram"
      />
      <SmallText content={content.aboutIntern} />
      <ImageText
        deviceWidth={deviceWidth}
        content={content.talentProgram}
        customClass="imageText--split-half"
        containerClass="talentProgram"
      />
      <Carousel
        deviceWidth={deviceWidth}
        content={content.internCarousel}
        containerClass="talentProgram"
      />
      <VerticalList
        content={content.internApply}
        customClass="section--blue"
        containerClass="talentProgram"
      />
      <VerticalList content={content.internTipps} customClass="section--grey" />
      <Accordion content={content.internFAQ} />
      <Jobs
        hideFilters={true}
        query="intern praktikant student"
        locale={locale}
        content={content.internJobs}
      />
      {/* <Contact content={content.internContact} containerClass="talentProgram" /> */}
    </>
  );
};

export default interns;
