import React from 'react';
import './Footer.css';

const footer = props => {
  const assets = process.env.PUBLIC_URL + '/Assets/';
  const {
    locale,
    content: { social, international, nav }
  } = props;

  return (
    <footer className="section section--footer">
      <div className="section__wrapper">
        <div className="social">
          {social.map((el, i) => {
            return (
              <a
                key={i}
                className="social_link"
                href={el.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img alt="" className="social__icon" src={assets + el.icon} />
              </a>
            );
          })}
        </div>

        <div className="international">
          <p className="international__title">{international.title}</p>
          <div className="international__flags">
            {international.links.map((el, i) => {
              return (
                <a
                  key={i}
                  className="international_link"
                  href={el.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img alt="" className="international_icon" src={assets + el.icon} />
                </a>
              );
            })}
          </div>
        </div>

        {locale !== 'en_US' && (
          <div className="nav">
            <div className="nav__links">
              {nav.map((el, i) => {
                return (
                  <a
                    key={i}
                    className="nav_link"
                    href={el.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {el.title}
                  </a>
                );
              })}
            </div>
          </div>
        )}

        {locale === 'en_US' && (
          <div className="nav-us">
            <div className="nav-us__grid">
              <div className="nav-us__col">
                <a className="nav-us__link" href="https://www.hear.com/imprint/">
                  Imprint
                </a>
              </div>
              <div className="nav-us__col">
                <a className="nav-us__link" href="https://www.hear.com/privacy-policy/">
                  Privacy Policy
                </a>
              </div>
              <div className="nav-us__col">
                <a className="nav-us__link" href="https://www.hear.com/privacy-request">
                  Do Not Sell My
                  <br /> Personal Information
                  <br /> <span>(CA Residents Only)</span>
                </a>
              </div>
              <div className="nav-us__col">
                <a
                  className="nav-us__link"
                  href="https://www.hear.com/hipaa-notice-of-privacy-practices/"
                >
                  HIPAA Notice of
                  <br /> Privacy Practices
                </a>
              </div>
              <div className="nav-us__col">
                <a className="nav-us__link" href="https://www.hear.com/mobile-terms-and-conditions">
                  Mobile Terms & Conditions
                </a>
              </div>
              <div className="nav-us__col">
                <a
                  className="nav-us__link"
                  href="https://www.hear.com/referral-terms-and-conditions"
                >
                  Referral Terms & Conditions
                </a>
              </div>
              <div className="nav-us__col">
                <a
                  className="nav-us__link"
                  href="https://www.hear.com/privacy-policy/?hcom_ad_choices=true"
                >
                  AdChoices
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
    </footer>
  );
};

export default footer;
