import React from 'react';
import './TrustWidgets.css';

const trustWidgets = props => {
  const {
    content: { items }
  } = props;

  return (
    <section className="trustwidgets">
      <div className="trustwidgets__wrapper">
        {items.map((el, i) => {
          return (
            <div key={i} className="trustwidgets__item">
              <a
                className="trustwidgets__link"
                href={el.url}
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                <img className="trustwidgets__img" src={el.imgSrc} alt={el.imgTitle} />
              </a>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default trustWidgets;
