import React, { Component } from 'react';
import axios from 'axios';
import Filters from './Filters/Filters';
import Department from './Department/Department';
import Masonry from 'react-masonry-css';
import './Jobs.css';
import { getLangFromLocale } from '../../Helpers/Utils';

class Jobs extends Component {
  endpointID = this.props.locale === 'en_US' ? 'hearcom' : 'audibenehearcom';
  defaultState = {
    jobs: [],
    departments: [],
    offices: [],
    filters: {
      current: {
        type: null,
        value: null
      },
      office: {
        selected: null
      },
      department: {
        selected: null
      },
      change: {
        selected: null
      }
    }
  };

  state = this.defaultState;

  componentDidMount() {
    this.getJobsByDep();
  }

  // Asynchronous call to fetch data from greenhouse
  getJobsByDep = () => {
    axios
      .get(`https://boards-api.greenhouse.io/v1/boards/${this.endpointID}/departments`)
      .then(result => {
        this.setState({ jobs: result.data.departments });
        this.setState({ offices: this.getOfficesFromDeps(result.data) });
        this.constructDeps();
        this.handleChangeOnLoad();
      });
  };

  getJobsByOffice = () => {
    axios
      .get(`https://boards-api.greenhouse.io/v1/boards/${this.endpointID}/offices`)
      .then(result => {
        this.setState({ offices: result.data.offices });
        this.constructOffices();
      });
  };

  constructDeps = () => {
    const departments = this.state.jobs
      .filter(el => el.jobs.length > 0 && el.id !== 0)
      .map(el => ({
        name: el.name,
        id: el.id
      }));

    this.setState({ departments: departments });
  };

  getOfficesFromDeps = data => {
    return data.departments
      .map(value => value.jobs.map(child => child.location.name))
      .flat()
      .filter((a, b, array) => array.indexOf(a) === b) // filter out dupes
      .map(item => ({ name: item }));
  };

  constructOffices = () => {
    let activeOffices = [];
    this.state.offices.map(el => {
      let deps = el.departments;
      if (deps && deps.length > 0 && el.id !== 0) {
        deps.map(dep => {
          let office = {
            name: el.name,
            id: el.id
          };
          if (dep.jobs && dep.jobs.length > 0) {
            activeOffices.push(office);
            this.attachOffice(dep, office);
          }
        });
      }
    });

    let uniqueActiveOffices = activeOffices.filter((item, index) => {
      return index > 0 ? item.id !== activeOffices[index - 1].id : item;
    });

    this.setState({ offices: uniqueActiveOffices });
  };

  attachOffice = (dep, office) => {
    dep.jobs.map(oJob => {
      this.state.jobs.map(dDep => {
        dDep.jobs.map(dJob => {
          if (dJob.id === oJob.id) {
            dJob.location = office;
          }
        });
      });
    });
  };

  getOptions = () => {
    let optionsOffices = this.state.offices.map(el => {
      if (el.hasJobs) {
        return { label: el.name, value: el.name };
      }
    });

    this.setState({ optionsOffices: optionsOffices });
  };

  filterJobs = filter => {
    let jobs = [...this.state.jobs];

    jobs.map((dep, index) => {
      let visibleJobs = 0;
      dep.jobs.map((job, i) => {
        dep.hidden = false;
        let selectedFilter = this.state.filters[filter].selected;
        if (selectedFilter) {
          if (filter === 'office') {
            job.hidden = false;
            if (selectedFilter !== job.location.name) {
              job.hidden = true;
            } else {
              visibleJobs += 1;
            }
          } else if (filter === 'department') {
            job.hidden = false;
            if (selectedFilter !== dep.name) {
              dep.hidden = true;
            }
          } else if (filter === 'change' && this.props.query) {
            //this part if used for the FuterTalents page
            job.hidden = true;
            let searchInput = selectedFilter.split(' ').filter(i => i);
            searchInput.forEach(item => {
              if (job.title.toLowerCase().includes(item.toLowerCase())) {
                job.hidden = false;
                visibleJobs += 1;
              }
            });
          } else if (filter === 'change') {
            job.hidden = false;
            if (!job.title.toLowerCase().includes(selectedFilter.toLowerCase())) {
              job.hidden = true;
            } else {
              visibleJobs += 1;
            }
          }

          this.setState(prevState => ({
            ...prevState,
            filters: {
              ...prevState.filters,
              current: {
                ...prevState.filters.current,
                type: filter,
                value: selectedFilter
              }
            }
          }));
        } else {
          dep.hidden = false;
        }
        if (filter === 'change' && selectedFilter) {
          dep.hidden = visibleJobs < 1 ? true : false;
        }
      });

      if (filter === 'office') {
        dep.hidden = visibleJobs < 1 ? true : false;
      }
    });

    this.setState({ jobs: jobs });
  };

  clearFilters = () => {
    this.setState({
      filters: this.defaultState.filters
    });

    const defaultJobs = this.state.jobs.map(job => {
      job.hidden = false;
      job.jobs.forEach(dep => {
        dep.hidden = false;
      });
      return job;
    });

    this.setState({
      jobs: defaultJobs
    });
  };

  handleChangeOnLoad = () => {
    const filter = 'change';
    let filters = this.state.filters;
    const { query = '' } = this.props;
    filters[filter].selected = query;
    filters.filtered = true;
    this.setState({ filters: filters });
    this.filterJobs(filter);
  };

  handleChange = event => {
    const filter = event.type;
    let filters = this.state.filters;
    filters.office.selected = null;
    filters.department.selected = null;
    filters[filter].selected = filter === 'change' ? event.target.value : event.value;
    filters.filtered = true;
    this.setState({ filters: filters });

    this.filterJobs(filter);
  };

  render() {
    const {
      locale,
      content: { title },
      hideFilters
    } = this.props;

    const lang = getLangFromLocale(locale);
    const departments = this.state.jobs.map(dep => {
      return <Department key={dep.id} id={dep.id} name={dep.name} department={dep} />;
    });
    const breakpointColumnsObj = {
      default: 2,
      960: 1
    };

    return (
      <section id="jobs" className="section section--jobs">
        <div className="section__wrapper">
          <div className="section__head">
            <h2 className="section__title">{title}</h2>
          </div>

          {!hideFilters && (
            <Filters
              offices={this.state.offices}
              departments={this.state.departments}
              filters={this.state.filters}
              state={this.state}
              change={this.handleChange}
              lang={lang}
              clearFilters={this.clearFilters}
            />
          )}

          <Masonry
            breakpointCols={this.props.query ? 1 : breakpointColumnsObj}
            className="jobs__container"
            columnClassName="jobs__column"
          >
            {departments}
          </Masonry>
        </div>
      </section>
    );
  }
}

export default Jobs;
