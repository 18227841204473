import React from 'react';
import './Success.scss';

const success = props => {
  const {
    content: { success }
  } = props;

  return (
    <div className="success">
      <div className="success__head">
        <h3 className="success__title blue-underline">{success.title}</h3>
      </div>
      <div className="success__items">
        {success.items.map((el, i) => {
          return (
            <div key={i} className="success__item">
              <p className="success__number">{el.number}</p>
              <p className="success__text">{el.text}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default success;
