import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import HamburgerMenu from 'react-hamburger-menu';
import './Header.scss';

const nav = props => {
  const {
    locale,
    content: { logo, items, url },
    isOpen,
    navClick,
    deviceWidth,
    hamClick,
    langClick
  } = props;

  const hideLang = props.location.pathname === '/sales' || locale === 'en_US';
  const isHome = props.location.pathname === '/';

  return (
    <header
      className={`section section--header ${
        deviceWidth < 992 ? (isOpen ? 'expand' : 'stack') : ''
      }`}
    >
      <div className="section__wrapper">
        <div className="header__items">
          <a href={url}>
            <img alt="logo" className="header__logo" src={logo} />
          </a>
          <nav className="header__nav">
            <ul className="nav__items">
              {items.map((el, i) => {
                return (
                  <li className="nav__item" key={i} onClick={() => isHome && navClick(el.anchor)}>
                    {!isHome ? (
                      <Link
                        key={i}
                        to={`/#${el.anchor}`}
                        onClick={() => hamClick()}
                        scroll={target => {
                          var offsetTop = target.offsetTop - (deviceWidth < 992 ? 90 : 120);
                          window.scrollTo({
                            top: offsetTop
                          });
                        }}
                      >
                        {el.text}
                      </Link>
                    ) : (
                      el.text
                    )}
                  </li>
                );
              })}
              {locale === 'de_DE' && (
                <li className="nav__item">
                  <Link to="/sales" onClick={() => hamClick()}>
                    Sales Team
                  </Link>
                </li>
              )}

              {(locale === 'de_DE' || locale === 'en_DE') && (
                <li className="nav__item">
                  <Link to="/futuretalents" onClick={() => hamClick()}>
                    Future Talents
                  </Link>
                </li>
              )}
              {locale !== 'en_US' && (
                <li className="nav__item">
                  <a
                    href="https://inside.audibene.de/"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => hamClick()}
                  >
                    Blog
                  </a>
                </li>
              )}
              {!hideLang && (
                <li className="lang">
                  <ul className="lang__list">
                    <li
                      className={'lang__el' + (locale === 'de_DE' ? ' active' : '')}
                      onClick={() => langClick('de_DE')}
                    >
                      DE
                    </li>
                    <li
                      className={'lang__el' + (locale === 'en_DE' ? ' active' : '')}
                      onClick={() => langClick('en_DE')}
                    >
                      EN
                    </li>
                  </ul>
                </li>
              )}
            </ul>
            <div className="nav__hamburger">
              <HamburgerMenu
                isOpen={isOpen}
                menuClicked={hamClick}
                width={25}
                height={15}
                strokeWidth={2}
                rotate={0}
                color="#265DA5"
                borderRadius={0}
                animationDuration={0.2}
              />
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default nav;
