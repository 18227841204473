import React from 'react';
import './IconsText.scss';

const iconsText = props => {
  const assets = process.env.PUBLIC_URL + '/Assets/';
  const {
    content: { title, items },
    id,
    deviceWidth,
    customClass = '',
    containerClass = ''
  } = props;
  const itemCount = items.length;

  return (
    <section id={id} className={`section section--iconsText ${customClass} ${containerClass}`}>
      <div className="section__wrapper">
        <div className="section__head">
          <h3 className="section__title blue-underline">{title}</h3>
        </div>

        <div className="section__content">
          <div className="iconsText">
            {items.map((el, i) => {
              return (
                <div key={i} className={`iconsText__item iconsText__item--count-${itemCount}`}>
                  <img alt="" className="iconsText__icon" src={assets + el.icon} />
                  {el.title && (
                    <h5 className="iconsText__subtitle">
                      {el.titleMobile && deviceWidth < 400 ? el.titleMobile : el.title}
                    </h5>
                  )}
                  <p className="iconsText__text">{el.text}</p>
                </div>
              );
            })}
          </div>
        </div>
        {props.children}
      </div>
    </section>
  );
};

export default iconsText;
