import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import './BlocksCarousel.scss';

const development = props => {
  const assets = process.env.PUBLIC_URL + '/Assets/';
  const {
    content: { items, title, subTitle, text },
    id,
    deviceWidth
  } = props;

  const devDesktop = items.map((el, i) => {
    return (
      <React.Fragment key={i}>
        <div className="blocksCarousel__item">
          {el.title && <h2 className="blocksCarousel__title">{el.title}</h2>}
          <div className="blocksCarousel__body">
            <img className="blocksCarousel__img" alt="" title="" src={assets + el.img} />
            <div className="blocksCarousel__text">
              {el.innerTitle && <h4 className="blocksCarousel__inner-title">{el.innerTitle}</h4>}
              <p className="blocksCarousel__inner-subtitle">
                {el.innerSubtitle}
                <img alt="" src={`${assets}info-icon.svg`} className="blocksCarousel__info-icon" />
              </p>
              <p className="blocksCarousel__inner-copy">{el.innerCopy}</p>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  });
  const devMobile = (
    <Carousel
      className="blocksCarousel__carousel"
      autoPlay
      swipeable={true}
      showThumbs={false}
      infiniteLoop={true}
      emulateTouch={true}
      interval={6000}
      transitionTime={600}
      showStatus={false}
    >
      {items.map((el, i) => {
        return (
          <div key={i} className="blocksCarousel__item">
            <div className="blocksCarousel__inner">
              <h2 className="blocksCarousel__title">{el.title}</h2>
              <div className="blocksCarousel__body">
                <img className="blocksCarousel__img" alt="" title="" src={assets + el.img} />
                <div className="blocksCarousel__text">
                  <h4 className="blocksCarousel__inner-title">{el.innerTitle}</h4>
                  <p className="blocksCarousel__inner-subtitle">{el.innerSubtitle}</p>
                </div>
              </div>
            </div>
            <p className="blocksCarousel__inner-copy blocksCarousel__inner-copy--mobile">
              {el.innerCopy}
            </p>
          </div>
        );
      })}
    </Carousel>
  );

  return (
    <section id={id} className="section section--blocksCarousel">
      <div className="section__wrapper">
        <div className="section__head">
          <h2 className="section__title blue-underline">{title}</h2>
        </div>
        <div className="section__content">
          <p className="section__text">{text}</p>
          {subTitle && <h3 className="section__sub-title">{subTitle}</h3>}
          <div className="blocksCarousel">{deviceWidth >= 992 ? devDesktop : devMobile}</div>
        </div>
      </div>
    </section>
  );
};

export default development;
