import React from 'react';
import './ImageText.scss';

const imageText = props => {
  const assets = process.env.PUBLIC_URL + '/Assets/';
  const {
    content: { title, blocks },
    deviceWidth,
    customClass = '',
    containerClass = ''
  } = props;

  return (
    <section className="section section--imageText">
      <div className="section__wrapper">
        <div className="section__head">
          <h3 className="section__title blue-underline">{title}</h3>
        </div>

        {blocks.map((el, i) => {
          return (
            <div key={i} className={`section__content imageText ${customClass} ${containerClass}`}>
              <img
                className="imageText__img"
                alt=""
                src={assets + (deviceWidth >= 660 ? el.img.desktop : el.img.mobile)}
              />
              <div className="imageText__content">
                {el.textBlock.title && (
                  <h5 className="imageText__subtitle">{el.textBlock.title}</h5>
                )}
                {el.textBlock.text && (
                  <p
                    className="imageText__text"
                    dangerouslySetInnerHTML={{ __html: el.textBlock.text }}
                  />
                )}

                {el.textBlock.list && (
                  <ul className="imageText__list">
                    {el.textBlock.list.map((el, i) => {
                      return <li key={i} dangerouslySetInnerHTML={{ __html: el }} />;
                    })}
                  </ul>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default imageText;
