import React from 'react';
import './SmallText.scss';

const smallText = props => {
  const {
    content: { title, blocks }
  } = props;

  return (
    <section className="section section--smallText section--contained">
      <div className="section__wrapper">
        <div className="section__head">
          <h3 className="section__title blue-underline">{title}</h3>
        </div>

        <div className="section__content smallText">
          {blocks.map((el, i) => {
            return (
              <p key={i} className="smallText__text">
                {el.text}
              </p>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default smallText;
