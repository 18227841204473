import React from 'react';
import './VerticalList.scss';

const verticalList = props => {
  const assets = process.env.PUBLIC_URL + '/Assets/';
  const {
    content: { title, text, cta, items },
    customClass = '',
    containerClass = ''
  } = props;

  return (
    <section className={`section section--contained ${customClass} ${containerClass}`}>
      <div className="section__wrapper">
        <div className="section__head">
          <h3 className="section__title blue-underline">{title}</h3>
        </div>

        <div className="section__content">
          <div className="verticalList">
            {text && <p className="verticalList__introText">{text}</p>}
            {items.map((el, i) => {
              return (
                <div key={i} className="verticalList__item">
                  {el.icon.src ? (
                    <img alt="" className="verticalList__icon" src={assets + el.icon.src} />
                  ) : (
                    <span
                      style={{ backgroundColor: el.icon.color }}
                      className="verticalList__icon verticalList__icon--number"
                    >
                      {i + 1}
                    </span>
                  )}
                  <p className="verticalList__text" dangerouslySetInnerHTML={{ __html: el.text }} />
                </div>
              );
            })}

            {cta && (
              <div className="verticalList__cta">
                <a href={cta.link} target="_blank" rel="noopener noreferrer" className="main-cta">
                  {cta.text}
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default verticalList;
