import React, { Component } from 'react';
import YoutubeBackgroundCustom from '../YoutubeBackgroundCustom/YoutubeBackgroundCustom';
import './Hero.css';

const assets = process.env.PUBLIC_URL + '/Assets/';

class Hero extends Component {
  state = { playState: '' };

  smoothScroll = target => {
    window.scrollTo({
      top: document.getElementById(target).offsetTop - (this.state.deviceWidth < 992 ? 90 : 120),
      behavior: 'smooth'
    });
  };

  handlePlay = () => {
    if (this.state.playState !== 'playing') {
      this.setState({ playState: 'playing' });
    }
  };

  render() {
    const {
      deviceWidth,
      content: { img, title, subtitle, cta, videoId }
    } = this.props;
    const heroImageStyle = {
      backgroundImage: 'url(' + assets + (deviceWidth >= 660 ? img.desktop : img.mobile) + ')'
    };
    let playerClasses = `${this.state.playState} section__wrapper section__wrapper--hero`;

    return (
      <section
        className={`section section--hero ${!videoId && 'section--hero-image'}  ${!videoId &&
          this.props.containerClass}`}
        style={heroImageStyle}
      >
        {videoId && (
          <YoutubeBackgroundCustom
            videoId={videoId}
            overlay="rgba(12, 44, 83, 0.5)"
            className={playerClasses}
            onPlay={this.handlePlay}
          ></YoutubeBackgroundCustom>
        )}
        <div className="hero__content">
          {title && <h1 className="hero__title">{title}</h1>}
          {subtitle && <p className="hero__text">{subtitle}</p>}
          {cta && cta.anchor && (
            <a onClick={() => this.smoothScroll(cta.anchor)} className="main-cta">
              {cta.text}
            </a>
          )}{' '}
          {cta && cta.link && (
            <a href={cta.link} target="_blank" rel="noopener noreferrer" className="main-cta">
              {cta.text}
            </a>
          )}
        </div>
      </section>
    );
  }
}

export default Hero;
