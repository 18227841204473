import React from 'react';
import './BigText.scss';

const bigText = props => {
  const {
    content: { title, text },
    id
  } = props;

  return (
    <section id={id} className="section section--contained section--bigText">
      <div className="section__wrapper">
        <div className="section__head">
          <h2 className="section__title">{title}</h2>
        </div>
        <div className="section__content">
          <p className="section__text">{text}</p>
          {props.children}
        </div>
      </div>
    </section>
  );
};

export default bigText;
